import { Fragment, useMemo, useState } from "react";
import { Icon } from "@shopify/polaris";
import {
  CirclePlusMinor,
  FormsMajor,
  HomeMajor,
  ManagedStoreMajor,
  DragHandleMinor,
  QuestionMarkMinor,
  AffiliateMajor,
  ColorsMajor,
  TransferMajor,
  EmailMajor,
} from "@shopify/polaris-icons";
import { Box, Flex, Text, css } from "@storyofams/react-ui";
import arrayMove from "array-move";
import produce from "immer";
import qs from "query-string";
import { useQueryClient } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import styled from "styled-components";

import { QuestionDeleteModal } from "~/components/QuestionDeleteModal";
import { QuestionTypeModal } from "~/components/QuestionTypeModal";
import { Flow, FlowNodeLayout, FlowNodeType } from "~/graphql/sdk";
import { useFlow, useNavParams, useSdk } from "~/hooks";
import { useBillingWrapper, useToast } from "~/lib";

import { NavButton } from "./NavButton";

const Hr = styled.div`
  margin: 8px 16px;
  height: 1px;
  background-color: #e2e3e5;
`;

const DragHandle = SortableHandle(() => (
  <Box
    width="0"
    height="20px"
    overflow="hidden"
    className="drag-handle"
    borderRadius="4px"
    cursor="grab"
    ml="auto"
    css={css({
      "&:hover": {
        bg: "black10",
      },
    })}
  >
    <Icon source={DragHandleMinor as any} />
  </Box>
));

const SortableItem = SortableElement(({ value }) => (
  <Box
    css={{
      "&.is-dragging .drag-handle": {
        width: "20px",
      },
    }}
    mb={0.5}
  >
    <NavButton
      {...value}
      icon={
        value.questionType === FlowNodeType.InputMultiLineText ||
        value.questionType === FlowNodeType.InputOneLineText
          ? FormsMajor
          : value.questionType === FlowNodeType.Transition
          ? TransferMajor
          : value.questionType === FlowNodeType.Email
          ? EmailMajor
          : QuestionMarkMinor
      }
      dragHandle={<DragHandle />}
    />
  </Box>
));

const SortableList = SortableContainer(({ children }) => {
  return (
    <Box
      css={{
        "&:hover .drag-handle": {
          width: "20px",
        },
      }}
    >
      {children}
    </Box>
  );
});

export const EditorPane = () => {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const { data } = useFlow();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const [{ question: activeQuestion, tab }, setNavParams] = useNavParams();
  const sdk = useSdk();
  const billingWrapperLogic = useBillingWrapper({
    freeForExistingV1Users: true,
  });

  const billingWrapperQuestions = useBillingWrapper({
    freeBeforeDate: new Date("2022-12-20"),
  });

  const [active, setActive] = useState(false);
  const [isBusy, setBusy] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<number | boolean>(
    false
  );
  const toast = useToast();

  const params = useMemo(() => qs.parse(search), [search]);

  const currentFlow = data?.flows?.[0];

  const hasWelcome = useMemo(
    () => currentFlow?.nodes?.[0]?.type === FlowNodeType.Welcome,
    [currentFlow]
  );

  const numQuestions = useMemo(() => {
    return currentFlow?.nodes?.filter(
      ({ type }) => type !== FlowNodeType.Email && type !== FlowNodeType.Welcome
    ).length;
  }, [currentFlow]);

  const setQuestion = (q: number) => {
    setNavParams({ tab: "0", question: `${q}` });
  };

  const showNewPagePanel = () => {
    setNavParams({ main: "7" });
  };

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (isBusy || !currentFlow) {
      return;
    }

    setBusy(true);

    try {
      const movedArray = arrayMove(currentFlow.nodes, oldIndex, newIndex);
      queryClient.setQueryData(["container", { id }], (old: any) => ({
        ...old,
        flows: [
          {
            ...old.flows?.[0],
            nodes: arrayMove(old.flows?.[0]?.nodes, oldIndex, newIndex),
          },
          ...old?.flows?.slice(1),
        ],
      }));

      await sdk.updateFlowNodesOrder({
        input: {
          flowId: currentFlow.id,
          order: movedArray.map((node) => node.id),
        },
      });

      if (oldIndex === activeQuestion) {
        setQuestion(newIndex);
      }
    } catch (e: any) {
      queryClient.invalidateQueries(id, { exact: true });
      toast({ content: e?.message, error: true });
    }

    setBusy(false);
  };

  const addFlowNode = async (
    type: FlowNodeType.Welcome | FlowNodeType.Email
  ) => {
    if (isBusy || !currentFlow) {
      return;
    }

    setBusy(true);

    try {
      const result = (
        await sdk.createOneFlowNode({
          input: {
            flowNode: {
              flowId: currentFlow.id,
              layout: FlowNodeLayout.Simple,
              isRequired: true,
              nextQuestionOnSelection: false,
              type,
              options: [],
            },
          },
        })
      ).createOneFlowNode;

      toast({
        content:
          type === FlowNodeType.Welcome
            ? "Welcome screen added"
            : "Email step added",
      });

      queryClient.setQueryData(["container", { id }], (old: any) =>
        produce(old, (draft) => {
          if (type === FlowNodeType.Welcome) {
            // Add welcome at start
            draft.flows?.[0]?.nodes?.unshift(result);
          } else {
            draft.flows?.[0]?.nodes?.push(result);
          }
        })
      );

      history.replace({
        pathname,
        search: qs.stringify({
          ...params,
          main: "0",
          question:
            type === FlowNodeType.Welcome ? 0 : currentFlow?.nodes?.length,
        }),
      });
    } catch (e: any) {
      toast({
        error: true,
        content:
          e?.messages?.[0] ||
          e?.message ||
          `Error adding ${
            type === FlowNodeType.Welcome ? "welcome screen" : "email step"
          }.`,
      });
    }

    setBusy(false);
  };

  if (!currentFlow) {
    return null;
  }

  // remap question numbers
  // transition screens are not counted
  // welcome screen is not counted
  const questionNumbers = {};
  let lastQuestionNumber = 0;
  currentFlow?.nodes?.map((question, idx) => {
    if (question.type === FlowNodeType.Welcome) {
      questionNumbers[idx] = "";
    } else if (question.type === FlowNodeType.Transition) {
      questionNumbers[idx] = "";
    } else if (question.type === FlowNodeType.Email) {
      questionNumbers[idx] = "";
    } else {
      questionNumbers[idx] = `${++lastQuestionNumber}.`;
    }
  });

  return (
    <>
      <Flex flexDirection="column" flex="1" justifyContent="space-between">
        <Box pt={1}>
          {!hasWelcome && (
            <>
              <NavButton
                color="blue"
                interactive
                text="Add welcome screen"
                icon={CirclePlusMinor}
                onClick={() => {
                  addFlowNode(FlowNodeType.Welcome);
                }}
              />
              <Hr />
            </>
          )}

          {/* @ts-ignore */}
          <SortableList
            onSortEnd={onSortEnd}
            helperClass="is-dragging"
            useDragHandle
          >
            {currentFlow?.nodes?.map((question, idx) => {
              const props = {
                isActive: activeQuestion === idx && tab !== 1,
                onClick: () => {
                  setQuestion(idx);
                },
              };

              if (question.type === FlowNodeType.Welcome) {
                return (
                  <Fragment key={question.id}>
                    <NavButton
                      {...props}
                      text="Welcome Page"
                      icon={HomeMajor}
                    />
                    <Hr />
                  </Fragment>
                );
              }

              return (
                <Fragment key={question.id}>
                  <SortableItem
                    index={idx}
                    // @ts-ignore
                    value={{
                      ...props,
                      questionType: question.type,
                      hasWelcome,
                      idx,
                      text: (
                        <>
                          <b>{questionNumbers[idx]}</b>
                          <Text
                            fontWeight={props.isActive ? 600 : 400}
                            ml={0.5}
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textAlign="left"
                            css={{ textOverflow: "ellipsis" }}
                            flex="1"
                          >
                            {question.title ||
                              (question.type === FlowNodeType.Transition
                                ? `Transition Screen`
                                : question.type === FlowNodeType.Email
                                ? `Email Capture Page`
                                : `Untitled`)}
                          </Text>
                        </>
                      ),
                    }}
                  />
                </Fragment>
              );
            })}
            <NavButton
              color="blue"
              interactive
              text="Add Page"
              icon={CirclePlusMinor}
              onClick={() => {
                if (window?.analytics) {
                  window.analytics.track("Flow Builder - Add Page Button");
                }
                if (numQuestions && numQuestions >= 3) {
                  billingWrapperQuestions(() => {
                    showNewPagePanel();
                  })();
                } else {
                  showNewPagePanel();
                }
              }}
            />
            <Hr />
            <NavButton
              onClick={() => {
                setQuestion(currentFlow?.nodes?.length);
              }}
              isActive={
                activeQuestion === currentFlow?.nodes?.length && tab !== 1
              }
              text="Results Page"
              icon={ManagedStoreMajor}
            />
          </SortableList>

          <QuestionTypeModal
            type="new"
            active={active}
            setActive={setActive}
            currentFlow={currentFlow as Flow}
          />
          <Hr />
        </Box>
        <div style={{ height: "100%", position: "relative" }}></div>
        <Box pt={1} style={{ marginBottom: 14 }}>
          <NavButton
            color="blue"
            onClick={() => {
              billingWrapperLogic(setNavParams)({ main: "1" });
            }}
            text="Logic Jumps"
            icon={AffiliateMajor}
          />
          <Hr />
          <NavButton
            color="blue"
            isActive={tab === 1}
            onClick={() => {
              setNavParams({ tab: "1" });
            }}
            text="Theme settings"
            icon={ColorsMajor}
          />
        </Box>
      </Flex>

      <QuestionDeleteModal
        isOpen={isDeleteModalOpen}
        setOpen={setDeleteModalOpen}
        currentFlow={currentFlow}
      />
    </>
  );
};
