import React, { FC, useMemo } from "react";
import { ErrorBanner } from "~/components";
import { IPublishErrors, useFlow } from "~/hooks";
import { useLocation } from "react-router";
import { Icon, IconSource, Link } from "@shopify/polaris";
import { ExternalMinor } from "@shopify/polaris-icons";
import { css, Text, Flex } from "@storyofams/react-ui";
import { FlowNodeType } from "~/graphql/sdk";

type PublishErrorBannerProps = Pick<
  IPublishErrors,
  "publishError" | "errorsLength" | "hasWelcomePage"
>;

const PUBLISH_ERRORS = [
  {
    error: "The flow should have at least one product assigned.",
    regexp: /^(The flow should have at least one product assigned\.)$/,
    queryParams: "?main=0&page=1&question=0&tab=0",
  },
  {
    error: "The flow should have at least one question.",
    regexp: /^(The flow should have at least one question\.)$/,
    queryParams: "?main=0&page=0&question=0&tab=0",
  },
  {
    error: "Question #${idx + 1} should have a title.",
    hasQuestion: true,
    regexp: /(should have a title\.)$/,
    queryParams: "?main=0&page=0&question={NUMBER}&tab=0",
  },
  {
    error: "Question #${idx + 1} should have at least one option.",
    hasQuestion: true,
    regexp: /(should have at least one option\.)$/,
    queryParams: "?main=0&page=0&question={NUMBER}&tab=0",
  },
  {
    error: "Question #${idx + 1} needs a property ID.",
    hasQuestion: true,
    regexp: /(needs a property ID\.)$/,
    queryParams: "?main=0&page=0&question={NUMBER}&tab=0",
  },
];

function getQuestionFromErrorMessage(msg: string) {
  return msg.replace(/[^0-9]*/g, "");
}

const ErrorMessageWithLink: FC<{
  message: string;
  hasWelcomePage: boolean;
}> = ({ message, hasWelcomePage }) => {
  const location = useLocation();
  const { data } = useFlow();
  const flow = data?.flows?.[0];

  if (!message) {
    return null;
  }

  const errorParams = PUBLISH_ERRORS.find(({ regexp }) =>
    message.match(regexp)
  );

  if (!errorParams) {
    return <React.Fragment key={message}>{message}</React.Fragment>;
  }

  let preparedQueryParams = errorParams.queryParams ?? "";

  // remap question numbers
  // transition screens are not counted
  // welcome screen is not counted
  const questionIds = {};
  let lastQuestionNumber = 0;
  flow?.nodes?.map((question, idx) => {
    if (
      question.type !== FlowNodeType.Welcome &&
      question.type !== FlowNodeType.Transition &&
      question.type !== FlowNodeType.Email
    ) {
      lastQuestionNumber++;
      questionIds[lastQuestionNumber] = idx;
    }
  });

  if (errorParams?.hasQuestion && errorParams?.queryParams) {
    const questionNumber = parseInt(getQuestionFromErrorMessage(message), 10);
    if (questionIds?.[questionNumber]) {
      preparedQueryParams = errorParams.queryParams.replace(
        "{NUMBER}",
        questionIds[questionNumber].toString(10)
      );
    }
  }

  const link = location.pathname + preparedQueryParams;

  return (
    <Flex>
      {message}
      <Text
        as="span"
        css={css({
          marginLeft: "0.25rem",
          '[class~="Polaris-Icon"]': {
            display: "inline-block",
          },
        })}
      >
        <Link url={link}>
          <Icon source={ExternalMinor as IconSource} />
        </Link>
      </Text>
    </Flex>
  );
};

const PublishErrorBanner: FC<PublishErrorBannerProps> = ({
  publishError,
  errorsLength,
  hasWelcomePage = false,
}) => {
  const handledMessages = useMemo(() => {
    const messages =
      publishError?.response?.errors?.[0]?.extensions?.exception?.response
        ?.message;

    if (Array.isArray(messages)) {
      return messages?.map((msg) => (
        <ErrorMessageWithLink
          message={msg}
          key={msg}
          hasWelcomePage={hasWelcomePage}
        />
      ));
    } else if (
      publishError?.response?.errors?.[0]?.extensions?.exception?.response
        ?.message
    ) {
      return [
        <ErrorMessageWithLink
          message={
            (publishError?.response?.errors?.[0]?.extensions?.exception
              ?.response?.message || "") as unknown as string
          }
          key={
            (publishError?.response?.errors?.[0]?.extensions?.exception
              ?.response?.message || "") as unknown as string
          }
          hasWelcomePage={hasWelcomePage}
        />,
      ];
    } else {
      return undefined;
    }
  }, [publishError]);

  return (
    <>
      {" "}
      <ErrorBanner
        title={`To publish this flow, ${errorsLength} ${
          errorsLength > 1 ? "changes need" : "change needs"
        } to be made:`}
        error={publishError}
        messages={handledMessages}
      />
    </>
  );
};

export default PublishErrorBanner;
