import { useEffect, useRef, useState } from "react";
import {
  DropZone,
  Stack,
  Icon,
  Button,
  Spinner,
  Tooltip,
  IconSource,
} from "@shopify/polaris";
import { ImageMajor } from "@shopify/polaris-icons";
import { CircleCancelMajor } from "@shopify/polaris-icons";
import { Flex, Box, css as cssUi, SystemProps } from "@storyofams/react-ui";
import { pick } from "@styled-system/props";
import produce from "immer";
import Imgix from "react-imgix";
import { useMutation, useQueryClient } from "react-query";
import styled, { css } from "styled-components";

import { FileUnion, UrlFile } from "~/graphql/sdk";
import { useFlow, useSdk } from "~/hooks";
import { getFileUrl, useToast } from "~/lib";

import { MediaModal } from "./MediaModal";

const ImgWrapper = styled(Box)<{ cover?: boolean }>`
  ${(p) => {
    if (!p.transition && p.cover) {
      return css`
        width: 100%;
        max-height: 100%;

        > img {
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      `;
    } else if (!p.transition && !p.cover) {
      return css`
        width: fit-content;
        max-height: 100%;

        border-radius: 8px;
        overflow: hidden;

        > img {
          height: auto;
          max-height: 100%;
          border-radius: 8px;
          overflow: hidden;
        }
      `;
    } else {
      if (!p.height && !p.width) {
        return css`
          width: 250px;

          > img {
            height: auto;
            max-height: 100%;
            overflow: hidden;
          }
        `;
      } else if (!p.height) {
        return css`
          width: ${p.width};

          > img {
            height: auto;
            max-height: 100%;
            overflow: hidden;
          }
        `;
      } else if (!p.width) {
        return css`
          height: ${p.height};
          width: fit-content;

          > img {
            height: auto;
            max-height: 100%;
            overflow: hidden;
          }
        `;
      } else {
        return css`
          height: ${p.height};
          width: ${p.width};

          > img {
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        `;
      }
    }
  }}
`;

const VideoWrapper = styled(Box)<{ cover?: boolean }>`
  width: 100%;
  max-height: 100%;

  ${(p) =>
    p.cover
      ? css`
          > video {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
          }
        `
      : css`
          border-radius: 8px;
          overflow: hidden;

          > video {
            height: auto;
            max-height: 100%;
            border-radius: 8px;
            overflow: hidden;
          }
        `}
`;

interface MediaInputProps extends SystemProps {
  alt: string;
  cover?: boolean;
  flowNodeId: string;
  imageProps?: SystemProps;
  minEmptyHeight?: string;
  minEmptyWidth?: string;
  sizes?: string;
  image?: FileUnion | null;
  video?: UrlFile | null;
}

export const MediaInput = ({
  alt,
  cover,
  image,
  video,
  flowNodeId,
  imageProps,
  minEmptyHeight,
  minEmptyWidth,
  sizes,
  ...props
}: MediaInputProps) => {
  const { data, setStatus } = useFlow();
  const queryClient = useQueryClient();
  const sdk = useSdk();
  const toast = useToast();

  const [isBusy, setBusy] = useState(false);
  const [isMutating, setMutating] = useState(false);
  const [isSelectModalOpen, setSelectModalOpen] = useState(false);
  const [img, setImg] = useState(image);
  const [file, setFile] = useState<any>();
  const videoRef = useRef<any>();
  const previousUrl = useRef(video?.url);

  useEffect(() => {
    setImg(image);
  }, [image]);

  useEffect(() => {
    if (previousUrl.current === video?.url) {
      return;
    }

    if (videoRef.current) {
      videoRef.current.load();
    }

    previousUrl.current = video?.url;
  }, [video]);

  const flowNodeMutation = useMutation(
    ({ id, values }: { id: string; values: any }) =>
      sdk.updateOneFlowNode({
        input: {
          id,
          update: values,
        },
      }),
    {
      onMutate: async ({ id, values }) => {
        setMutating(true);

        const key = ["container", { id: data?.id }];

        await queryClient.cancelQueries(key);

        const previous = queryClient.getQueryData(key);

        queryClient.setQueryData(key, (old: any) =>
          produce(old, (draft) => {
            const index = draft.flows?.[0]?.nodes?.findIndex(
              (node) => node?.id === id
            );

            //Set the image to undefined so that the previous image doesn't show on delete
            setImg(undefined);

            if (index !== -1) {
              draft.flows[0].nodes[index] = {
                ...draft.flows[0].nodes[index],
                ...(values.video
                  ? {
                      //only video
                      video: {
                        ...values.video,
                        __typename: "URLFile",
                      },
                      image: null,
                    }
                  : values.image
                  ? {
                      //Only image
                      image: {
                        ...values.image,
                        __typename: "URLFile",
                      },
                      video: null,
                    }
                  : {
                      //Remove media
                      image: null,
                      video: null,
                    }),
              };
            }
          })
        );

        setFile(undefined);

        return { previous };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["container", { id: data?.id }]);
        setStatus("saved");
      },
      onError: (e: any, { values }, context: any) => {
        setMutating(false);

        queryClient.setQueryData(
          ["container", { id: data?.id }],
          context?.previous
        );
        setStatus("error");
        toast({
          error: true,
          content:
            e?.messages?.[0] ||
            e?.message ||
            `Error saving ${values.video ? "video" : "image"}`,
        });
      },
    }
  );

  const uploadFile = async (file) => {
    if (isBusy) {
      return;
    }

    setBusy(true);

    try {
      await sdk.uploadImageForFlowNode({
        input: {
          image: file,
          nodeId: flowNodeId,
        },
      });

      setFile(window.URL.createObjectURL(file));

      if (isSelectModalOpen) {
        setSelectModalOpen(false);
      }
    } catch (e) {
      toast({ content: "Error uploading image", error: true });
    }

    setBusy(false);
  };

  const handleDrop = (_droppedFiles, acceptedFiles, rejectedFiles) => {
    if (rejectedFiles?.[0]) {
      toast({
        content: `"${rejectedFiles?.[0].name}" is not supported. File type must be .jpg or .png.`,
        error: true,
      });
    } else if (acceptedFiles?.[0]) {
      uploadFile(acceptedFiles[0]);
    }
  };

  useEffect(
    () => () => {
      if (file) {
        window.URL.revokeObjectURL(file);
      }
    },
    []
  );

  const openSelectModal = () => {
    setSelectModalOpen(true);
  };

  const onMediaLoad = () => {
    if (isMutating) {
      setMutating(false);
    }
  };

  const fileUpload = !file && !video && !img && (
    <Flex
      width="100%"
      height="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      css={{
        ".Polaris-Icon": {
          width: "32px",
          height: "32px",
        },
      }}
    >
      <Stack vertical spacing="tight">
        <Icon source={ImageMajor as IconSource} color="subdued" />
        <Button plain>Add media</Button>
      </Stack>
    </Flex>
  );

  const imgSrc = file || (img ? getFileUrl(img) : undefined);

  const uploadedFiles = (
    <Box>
      <Flex
        css={{
          "&:hover, &:focus": {
            ".change-button": {
              opacity: 1,
            },
          },
          height: imageProps?.transition ? "fit-content" : "100vh",
        }}
      >
        {video ? (
          <VideoWrapper cover={cover} className="video-wrapper">
            <video
              ref={videoRef}
              preload="auto"
              loop
              autoPlay
              muted
              playsInline
              controls={false}
              onLoadedData={onMediaLoad}
            >
              <source type="video/mp4" src={video.url} />
            </video>
          </VideoWrapper>
        ) : (
          <ImgWrapper cover={cover} {...imageProps} className="image-wrapper">
            {!!imgSrc && (
              <>
                {file ||
                (process.env.NODE_ENV === "development" &&
                  img?.__typename !== "URLFile") ||
                (img?.__typename === "URLFile" &&
                  img.url.includes("giphy.com")) ? (
                  <img src={imgSrc} alt={alt} onLoad={onMediaLoad} />
                ) : (
                  <Imgix
                    className="lazyload"
                    src={imgSrc}
                    sizes={sizes}
                    attributeConfig={{
                      src: "data-src",
                      srcSet: "data-srcset",
                      sizes: "data-sizes",
                    }}
                    htmlAttributes={{
                      alt,
                      onLoad: onMediaLoad,
                    }}
                  />
                )}
              </>
            )}
          </ImgWrapper>
        )}

        <Flex
          position="absolute"
          alignItems="center"
          justifyContent="center"
          top={0}
          right={0}
          left={0}
          bottom={0}
          opacity={0}
          className="change-button"
          transition="opacity 0.2s ease-out"
          bg="black40"
        >
          <Button>Change media</Button>
        </Flex>
        <Box
          className="remove"
          position="absolute"
          top="6px"
          right="0px"
          width="32px"
          height="32px"
          css={{
            ".Polaris-Button--iconOnly": {
              padding: 0,
            },
            ".Polaris-Icon": {
              width: "32px",
              height: "32px",

              svg: {
                backgroundColor: "white",
                borderRadius: "50%",
              },
            },
          }}
        >
          <Tooltip content="Remove Media" dismissOnMouseOut>
            <Button
              icon={CircleCancelMajor as IconSource}
              plain
              onClick={async () => {
                await flowNodeMutation.mutate({
                  id: flowNodeId,
                  values: { image: null, video: null },
                });
              }}
            />
          </Tooltip>
        </Box>
      </Flex>
    </Box>
  );

  const hasMedia = !!img || !!video || !!file;

  return (
    <>
      <Box
        width={hasMedia ? "100%" : minEmptyWidth ? minEmptyWidth : "100%"}
        height={!hasMedia ? minEmptyHeight : "100%" || "100%"}
        borderRadius={cover ? undefined : "8px"}
        overflow="hidden"
        position="relative"
        css={cssUi({
          img: { ml: "auto", mr: "auto" },
          "> div": {
            height: "100%",
          },
          ".Polaris-DropZone": {
            bg: hasMedia ? "transparent" : "black10",
            minHeight: "100%",
            height: "100%",
            transition: "background 0.2s ease",
            borderRadius: cover ? "none" : undefined,
          },
          ".Polaris-DropZone:hover": {
            bg: "black4",
          },
          ".Polaris-DropZone--hasOutline": {
            padding: 0,
            "&::after": {
              display: "none",
            },
          },
          ".Polaris-DropZone__Container": {
            display: "flex",
            justifyContent: "center",
          },
        })}
        {...pick(props)}
      >
        <DropZone
          accept="image/*"
          type="image"
          onDrop={handleDrop}
          onClick={openSelectModal}
          allowMultiple={false}
          variableHeight
        >
          {hasMedia && uploadedFiles}
          {fileUpload}
        </DropZone>

        {(isBusy || isMutating) && hasMedia && (
          <Flex
            position="absolute"
            alignItems="center"
            justifyContent="center"
            top={0}
            right={0}
            left={0}
            bottom={0}
            bg="white60"
          >
            <Spinner />
          </Flex>
        )}
      </Box>

      <MediaModal
        isOpen={isSelectModalOpen}
        setOpen={setSelectModalOpen}
        onDrop={handleDrop}
        onSelectImage={(newImage) => {
          flowNodeMutation.mutate({
            id: flowNodeId,
            values: { image: newImage },
          });
          setSelectModalOpen(false);
        }}
        onSelectVideo={(newVideo) => {
          flowNodeMutation.mutate({
            id: flowNodeId,
            values: { video: newVideo },
          });
          setSelectModalOpen(false);
        }}
        onSelectGif={(newGif) => {
          flowNodeMutation.mutate({
            id: flowNodeId,
            values: { image: newGif },
          });
          setSelectModalOpen(false);
        }}
        isBusy={isBusy}
      />
    </>
  );
};
