import {
  Button,
  Stack,
  Tooltip,
  Icon,
  Thumbnail,
  Spinner,
  IconSource,
  Modal,
} from "@shopify/polaris";
import { useSdk } from "~/hooks";
import { useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import {
  CancelSmallMinor,
  QuestionMarkMajor,
  ProductsMinor,
  ImageMajor,
  CollectionsMajor,
  VariantMajor,
  ToggleMinor,
} from "@shopify/polaris-icons";
import { ProductsModal } from "./ProductsModal";
import { getShopifyImage } from "~/lib";
import { Box } from "@storyofams/react-ui";

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
async function sleep(seconds, fn, ...args) {
  await timeout(seconds * 1000);
  return fn(...args);
}

export const ProductSearch = ({
  index,
  productIds,
  variantIds,
  collectionIds,
  isExcluded,
  onSave,
  matchAll,
  matchAllExcluded,
}) => {
  const sdk = useSdk();

  const [showModal, setShowModal] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    productIds || []
  );
  const [selectedVariantOptions, setSelectedVariantOptions] = useState<
    string[]
  >(variantIds || []);
  const [selectedCollectionOptions, setSelectedCollectionOptions] = useState<
    string[]
  >(collectionIds || []);

  const [products, setProducts] = useState({});
  const [variants, setVariants] = useState({});
  const [isMatchAll, setIsMatchAll] = useState(
    isExcluded ? matchAllExcluded : matchAll
  );

  const [throttlingModalMessage, setThrottlingModalMessage] = useState(
    null as string | null
  );
  const [showThrottlingModal, setShowThrottlingModal] = useState(false);

  useEffect(() => {
    const messageHandler = async (event) => {
      if (
        event?.data?.type === "addProducts" &&
        event?.data?.target === `productsSearch_${index}_${isExcluded ? 1 : 0}`
      ) {
        if (event.data.payload) {
          const result = event.data.payload;
          const selection = result ?? [];
          const productIds = selection.map((item) => item.id);
          setSelectedOptions(productIds);
        }
        setShowModal(false);
      } else if (
        event?.data?.type === "addVariants" &&
        event?.data?.target === `productsSearch_${index}_${isExcluded ? 1 : 0}`
      ) {
        if (event.data.payload) {
          const result = event.data.payload;
          const selection = result ?? [];
          const variantIds = [] as string[];
          for (const product of selection) {
            const productId = product.id;
            const variants = product.variants;
            for (const variant of variants) {
              variantIds.push(`${productId}###variant###${variant.id}`);
            }
          }
          setSelectedVariantOptions(variantIds);
        }
        setShowModal(false);
      } else if (
        event?.data?.type === "addCollections" &&
        event?.data?.target === `productsSearch_${index}_${isExcluded ? 1 : 0}`
      ) {
        if (event.data.payload) {
          const result = event.data.payload;
          const selection = result ?? [];
          const collectionIds = selection.map((item) =>
            JSON.stringify({
              id: item.id,
              title: item.title,
              handle: item.handle,
              productsCount: item.productsCount,
              image: item.image,
            })
          );
          await addCollections(collectionIds);
          setSelectedCollectionOptions(collectionIds);
        }
        setShowModal(false);
      } else {
      }
    };

    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showModal]);

  //https://shopify.dev/api/usage/rate-limits
  const SHOPIFY_MAX_ARRAY_SIZE = 250;

  //Product Details for display
  const {
    isFetching: productDetailsLoading,
    data: productDetails,
    refetch: refetchProducts,
  } = useQuery(
    [`productsDetailsSearch_${index}_${isExcluded ? 1 : 0}`],

    async () => {
      //Abide by shopify's api rate limit

      //1. Split array into chunks of 250
      let productChunks: any[] = [];
      let productIdsCopy: any[] = Array.from(
        new Set([
          ...selectedOptions.filter((option) => !products[option]),
          ...selectedVariantOptions
            .filter((option) => !products[option.split("###variant###")[0]])
            .map((option) => option.split("###variant###")[0]),
        ])
      );

      let numProducts = productIdsCopy.length;
      let totalChunks = Math.ceil(numProducts / SHOPIFY_MAX_ARRAY_SIZE);
      for (let i = totalChunks; i > 0; i--) {
        productChunks.push(
          productIdsCopy.splice(0, Math.ceil(numProducts / i))
        );
      }

      //2. Call shopify api
      let productDetails: any[] = [];

      //We use a while loop because forEach & map are more difficult for async calls
      let i = 0;
      while (i < productChunks.length) {
        const results = await sdk
          .shopifyProductsByIds({
            input: { ids: productChunks[i] || [], locale: "en" },
          })
          .then((res) => res.shopifyProductsByIds)
          .catch((error) => console.log(error));

        productDetails.push(...(results || []));
        i++;
      }

      // 3. Add variants to productDetails
      const variantDetails: any[] = [];

      const variantIds = selectedVariantOptions.map(
        (option) => option.split("###variant###")[1]
      );
      const variantIdsChunks: any[] = [];
      let variantIdsCopy: any[] = Array.from(new Set(variantIds));
      let numVariants = variantIdsCopy.length;
      let totalVariantChunks = Math.ceil(numVariants / SHOPIFY_MAX_ARRAY_SIZE);
      for (let i = totalVariantChunks; i > 0; i--) {
        variantIdsChunks.push(
          variantIdsCopy.splice(0, Math.ceil(numVariants / i))
        );
      }

      //We use a while loop because forEach & map are more difficult for async calls
      let j = 0;
      while (j < variantIdsChunks.length) {
        const results = await sdk
          .shopifyVariantsByIds({
            input: { ids: variantIdsChunks[j] || [], locale: "en" },
          })
          .then((res) => res.shopifyVariantsByIds)
          .catch((error) => console.log(error));

        variantDetails.push(...(results || []));
        j++;
      }

      const variantMap = variants;
      variantDetails.forEach((variant) => {
        variantMap[variant.id] = variant;
      });

      setVariants(variantMap);

      //4. Return the product map
      const productMap = products;
      productDetails.forEach((product) => {
        productMap[product.id] = product;
      });

      setProducts(productMap);
      return productMap;
    },
    { enabled: true, refetchOnWindowFocus: false }
  );

  //Auto save the answers
  useEffect(() => {
    const runEffect = async () => {
      if (
        selectedOptions !== productIds ||
        selectedVariantOptions !== variantIds ||
        selectedCollectionOptions !== collectionIds ||
        isMatchAll !== (isExcluded ? matchAllExcluded : matchAll)
      ) {
        onSave({
          productIds: selectedOptions,
          variantIds: selectedVariantOptions,
          collectionIds: selectedCollectionOptions,
          matchAll: isExcluded ? matchAll : isMatchAll,
          matchAllExcluded: isExcluded ? isMatchAll : matchAllExcluded,
        });
      }
      refetchProducts();
    };

    runEffect();
  }, [
    selectedOptions,
    selectedVariantOptions,
    selectedCollectionOptions,
    isMatchAll,
  ]);

  // auto save on mount
  useEffect(() => {
    const runEffect = async () => {
      onSave({
        productIds: selectedOptions,
        variantIds: selectedVariantOptions,
        collectionIds: selectedCollectionOptions,
        matchAll: isExcluded ? isMatchAll : matchAll,
        matchAllExcluded: isExcluded ? matchAllExcluded : isMatchAll,
      });
    };

    runEffect();
  }, []);

  const addCollections = async (collections) => {
    for (const json of collections) {
      const collection = JSON.parse(json);
      const id = collection?.id;
      if (!id) {
        continue;
      }
      const data = await sdk.collectionExists({
        input: { collectionId: id },
      });
      if (!data?.collectionExists?.exists) {
        // request product IDs from shopify
        const { shopifyProductsByCollectionId: products } =
          await sdk.shopifyProductsByCollectionId({
            input: { id },
          });

        // show a blocking popup for enough time
        // to cover the shopify api call
        // number of products / 50 * 1 second (rounded up)
        const seconds = Math.ceil(products.length / 50);
        if (products.length >= 250) {
          setThrottlingModalMessage(
            "Please note that due to Shopify API limits we can only link 250 products from each collection."
          );
        } else {
          setThrottlingModalMessage(null);
        }

        if (seconds > 0) {
          setShowThrottlingModal(true);
          await sleep(seconds, () => {
            setShowThrottlingModal(false);
          });
        }

        await sdk.createCollection({
          input: {
            collectionId: id,
            products,
          },
        });
      }
    }
  };

  const removeOption = (tag) => {
    setSelectedOptions(selectedOptions.filter((opt) => opt !== tag));
  };

  const removeVariantOption = (combinedIds) => {
    setSelectedVariantOptions(
      selectedVariantOptions.filter((opt) => opt !== combinedIds)
    );
  };

  const removeCollectionOption = (id) => {
    setSelectedCollectionOptions((currentCollections) =>
      currentCollections.filter((item) => {
        return JSON.parse(item).id !== id;
      })
    );
  };

  const optionDisplay = (title: string) => {
    if (title) {
      return (
        <>
          <p
            style={{
              maxWidth: "6.25rem",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {title}
          </p>
        </>
      );
    } else {
      return (
        <Spinner accessibilityLabel="Small spinner example" size="small" />
      );
    }
  };

  const verticalContentMarkup = useMemo(() => {
    if (isMatchAll) {
      return (
        <div
          style={{
            backgroundColor: isExcluded ? "#aa0000" : "#000000",
            color: "#ffffff",
            padding: "0.25rem 0.5rem",
            width: "100%",
            fontSize: 14,
            borderRadius: 5,
            display: "flex",
            alignItems: "center",
            cursor: "default",
          }}
        >
          {isExcluded
            ? "Exclude All Other Products, Variants & Collections (except the ones which are a positive match for this question)"
            : "Match All Products, Variants & Collections"}
        </div>
      );
    }

    return selectedOptions?.length > 0 ||
      selectedVariantOptions?.length > 0 ||
      selectedCollectionOptions?.length > 0 ? (
      <Stack spacing="extraTight" alignment="center">
        {selectedOptions.map((tag, index) => (
          <div
            key={(products && products[tag]?.title) ?? index}
            style={{
              backgroundColor: isExcluded ? "#FED3D1" : "#AEE9D1",
              padding: "0.25rem 0.5rem",
              width: "9.8rem",
              fontSize: 14,
              borderRadius: 2,
              display: "flex",
              alignItems: "center",
              cursor: "default",
            }}
          >
            <Stack spacing="tight" vertical={false} wrap={false}>
              <div style={{ marginRight: 5 }}>
                <Thumbnail
                  size="extraSmall"
                  alt={tag}
                  source={
                    products?.[tag]?.featuredImage?.transformedSrc
                      ? getShopifyImage(
                          (products &&
                            products[tag]?.featuredImage?.transformedSrc) ||
                            "",
                          "30x30"
                        )
                      : ImageMajor
                  }
                ></Thumbnail>
              </div>
              <div style={{ width: 80, cursor: "default" }}>
                <Tooltip content={products && products[tag]?.title}>
                  {optionDisplay(products && products[tag]?.title)}
                </Tooltip>
              </div>
              <div style={{ marginLeft: "auto", display: "flex" }}>
                <Button
                  plain
                  key={`option-${tag}`}
                  icon={CancelSmallMinor as IconSource}
                  onClick={() => removeOption(tag)}
                ></Button>
              </div>
            </Stack>
          </div>
        ))}
        {selectedVariantOptions.map((combinedIds, index) => {
          const [productId, variantId] = combinedIds.split("###variant###");
          const title = `${products[productId]?.title ?? ""} - ${
            variants?.[variantId]?.title ?? ""
          }`;
          return (
            <div
              key={title ?? index}
              style={{
                backgroundColor: isExcluded ? "#fca97f" : "#fff065",
                padding: "0.25rem 0.5rem",
                width: "9.8rem",
                fontSize: 14,
                borderRadius: 2,
                display: "flex",
                alignItems: "center",
                cursor: "default",
              }}
            >
              <Stack spacing="tight" vertical={false} wrap={false}>
                <div style={{ marginRight: 5 }}>
                  <Thumbnail
                    size="extraSmall"
                    alt={title}
                    source={
                      products?.[productId]?.featuredImage?.transformedSrc
                        ? getShopifyImage(
                            (products &&
                              products[productId]?.featuredImage
                                ?.transformedSrc) ||
                              "",
                            "30x30"
                          )
                        : ImageMajor
                    }
                  ></Thumbnail>
                </div>
                <div style={{ width: 80, cursor: "default" }}>
                  <Tooltip content={title}>{optionDisplay(title)}</Tooltip>
                </div>
                <div style={{ marginLeft: "auto", display: "flex" }}>
                  <Button
                    plain
                    key={`option-${title}`}
                    icon={CancelSmallMinor as IconSource}
                    onClick={() => removeVariantOption(combinedIds)}
                  ></Button>
                </div>
              </Stack>
            </div>
          );
        })}
        {selectedCollectionOptions.map((tag, index) => {
          const properties = JSON.parse(tag);
          const title = `${properties?.title ?? ""}`;
          const id = `${properties?.id ?? ""}`;
          const key = `${properties?.id ?? index}`;
          const image = `${properties?.image?.originalSrc ?? ""}`;
          return (
            <div
              key={key}
              style={{
                backgroundColor: isExcluded ? "#f1a7d2" : "#aec7e9",
                padding: "0.25rem 0.5rem",
                width: "9.8rem",
                fontSize: 14,
                borderRadius: 2,
                display: "flex",
                alignItems: "center",
                cursor: "default",
              }}
            >
              <Stack spacing="tight" vertical={false} wrap={false}>
                <div style={{ marginRight: 5 }}>
                  <Thumbnail
                    size="extraSmall"
                    alt={tag}
                    source={
                      image ? getShopifyImage(image, "30x30") : ImageMajor
                    }
                  ></Thumbnail>
                </div>
                <div style={{ width: 80, cursor: "default" }}>
                  <Tooltip content={title}>{optionDisplay(title)}</Tooltip>
                </div>
                <div style={{ marginLeft: "auto", display: "flex" }}>
                  <Button
                    plain
                    key={`option-${id}`}
                    icon={CancelSmallMinor as IconSource}
                    onClick={() => removeCollectionOption(id)}
                  ></Button>
                </div>
              </Stack>
            </div>
          );
        })}
      </Stack>
    ) : null;
  }, [
    productDetails,
    productDetailsLoading,
    selectedOptions,
    selectedVariantOptions,
    selectedCollectionOptions,
    isMatchAll,
  ]);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex" }}>
        <p>{isExcluded ? "Excluded Products" : "Matched Results"}</p>

        <span
          style={{
            display: "inline-block",
            marginLeft: "0.5rem",
            maxWidth: "1.125rem",
          }}
        >
          <Tooltip
            content={
              isExcluded
                ? "Add products to exclude them from being shown in results for this answer."
                : "Add products to match them to answers."
            }
          >
            <Icon source={QuestionMarkMajor as IconSource} />
          </Tooltip>
        </span>
      </div>
      <Box my={2}>{verticalContentMarkup}</Box>
      <div style={{ marginTop: 10 }}>
        <span style={{ marginRight: 30 }}>
          <Button
            icon={ProductsMinor as IconSource}
            plain
            disabled={isMatchAll}
            onClick={() => {
              window?.top?.frames?.["app-iframe"]?.postMessage(
                {
                  type: "product",
                  payload: {
                    selectedOptions,
                  },
                  target: `productsSearch_${index}_${isExcluded ? 1 : 0}`,
                },
                "*"
              );
            }}
          >
            {isExcluded ? "Exclude" : "Match"} Products
          </Button>
        </span>
        <span style={{ marginRight: 30 }}>
          <Button
            icon={VariantMajor as IconSource}
            plain
            disabled={isMatchAll}
            onClick={() => {
              // setShowModal(true);
              window?.top?.frames?.["app-iframe"]?.postMessage(
                {
                  type: "variant",
                  payload: {
                    selectedVariantOptions,
                  },
                  target: `productsSearch_${index}_${isExcluded ? 1 : 0}`,
                },
                "*"
              );
            }}
          >
            {isExcluded ? "Exclude" : "Match"} Variants
          </Button>
        </span>
        <span style={{ marginRight: 30 }}>
          <Button
            icon={CollectionsMajor as any}
            plain
            disabled={isMatchAll}
            onClick={() => {
              // setShowModal(true);
              window?.top?.frames?.["app-iframe"]?.postMessage(
                {
                  type: "collection",
                  payload: {
                    selectedCollectionOptions: selectedCollectionOptions.map(
                      (jsonString) => {
                        const { id } = JSON.parse(jsonString);
                        return id;
                      }
                    ),
                  },
                  target: `productsSearch_${index}_${isExcluded ? 1 : 0}`,
                },
                "*"
              );
            }}
          >
            {isExcluded ? "Exclude" : "Match"} Collections
          </Button>
        </span>
        <span
          style={{
            marginRight: 30,
            display: "inline-flex",
            gap: "5px",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Button
            icon={ToggleMinor as IconSource}
            plain
            destructive={isMatchAll}
            onClick={() => {
              setIsMatchAll(!isMatchAll);
            }}
          >
            {" "}
            {isExcluded ? "Exclude All Other" : "Match All"}
          </Button>
        </span>
      </div>

      <ProductsModal
        active={showModal}
        toggleActive={() => setShowModal(!showModal)}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        selectedVariantOptions={selectedVariantOptions}
        setSelectedVariantOptions={setSelectedVariantOptions}
        edit
      />

      <Modal
        open={showThrottlingModal}
        onClose={() => setShowThrottlingModal(false)}
        title="Linking Collection"
      >
        <Modal.Section>
          <Stack alignment="center" vertical={true}>
            <Spinner size="large" />
            <h1>Fetching products from Shopify...</h1>
            {throttlingModalMessage && (
              <p>
                <b>IMPORTANT:</b> {throttlingModalMessage}
              </p>
            )}
          </Stack>
        </Modal.Section>
      </Modal>
    </div>
  );
};
