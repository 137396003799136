import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  ColorPicker,
  Popover,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { CaretDownMinor, CaretUpMinor } from "@shopify/polaris-icons";
import { Box, Flex, Text } from "@storyofams/react-ui";
import { parseToHsl, hsl, readableColor } from "polished";
import styled from "styled-components";
import validator from "validator";

const ButtonWrapper = styled.div<{ bg?: string; iconColor: string }>`
  button {
    width: 64px;

    > span {
      justify-content: flex-end;

      svg {
        fill: ${(p) => p.iconColor};
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: ${(p) => p.bg};
    }
  }
`;

const toHsl = (color: string) => {
  const result: any = parseToHsl(color);

  return {
    ...result,
    brightness: result.lightness,
  };
};

const hsl2hsv = ({ hue, saturation, lightness }) => {
  const v = saturation * Math.min(lightness, 1 - lightness) + lightness;

  return {
    hue,
    saturation: v ? 2 - (2 * lightness) / v : 0,
    brightness: v,
  };
};

const hsv2hsl = ({ hue, saturation, brightness: v }) => {
  const lightness = v - (v * saturation) / 2;
  const m = Math.min(lightness, 1 - lightness);

  return {
    hue,
    saturation: m ? (v - lightness) / m : 0,
    lightness,
  };
};

interface ColorInputProps {
  label?: string;
  onChange(value: string): void;
  value: string;
}

export const ColorInput = forwardRef(
  ({ label, value, onChange }: ColorInputProps, ref: any) => {
    const [popoverActive, setPopoverActive] = useState(false);
    const previousValue = useRef(value);

    const togglePopoverActive = useCallback(
      () => setPopoverActive((popoverActive) => !popoverActive),
      []
    );

    const [error, setError] = useState("");
    const [hexColor, setHexColor] = useState(value);

    //When the color value changes due to the color picker we need to update the hex value
    useEffect(() => {
      setHexColor(value);
      setError(""); //Assume that the prop "value" is not in an error state
    }, [value]);

    const color = useMemo(() => {
      try {
        const hslColor = toHsl(value);
        previousValue.current = value;
        return hsl2hsv(hslColor);
      } catch (e) {
        try {
          return toHsl(previousValue.current);
        } catch (e) {
          return {};
        }
      }
    }, [value]);

    return (
      <Popover
        active={popoverActive}
        activator={
          <Flex alignItems="center">
            <ButtonWrapper
              bg={previousValue.current}
              ref={ref}
              iconColor={readableColor(
                previousValue.current,
                "rgba(0, 0, 0, 0.8)",
                "rgba(255, 255, 255, 0.8)"
              )}
              onClick={togglePopoverActive}
            >
              <Button
                icon={
                  popoverActive
                    ? (CaretUpMinor as any)
                    : (CaretDownMinor as any)
                }
              />
            </ButtonWrapper>

            {!!label && (
              <Text
                display="block"
                as="label"
                htmlFor="primaryColor"
                ml={1.5}
                onClick={togglePopoverActive}
              >
                <TextStyle variation="subdued">{label}</TextStyle>
              </Text>
            )}
          </Flex>
        }
        onClose={togglePopoverActive}
        preferredAlignment="left"
      >
        <Box p={2}>
          <Stack vertical>
            {/* @ts-ignore */}
            <ColorPicker
              color={color}
              onChange={(newValue: any) => {
                onChange(hsl(hsv2hsl(newValue)));
              }}
            />
            <TextField
              autoComplete="off"
              label="Color hex value"
              labelHidden
              onChange={(newValue: string) => {
                if (validator.isHexColor(newValue) && newValue[0] === "#") {
                  setError("");
                  onChange(newValue);
                } else {
                  setError("Invalid Hex Color Code (Ex #FFFFFF");
                }
                setHexColor(newValue);
              }}
              onBlur={() =>
                validator.isHexColor(hexColor) && hexColor[0] === "#"
                  ? onChange(hexColor)
                  : null
              }
              value={hexColor}
            />
            <p style={{ color: "red" }}>{error}</p>
          </Stack>
        </Box>
      </Popover>
    );
  }
);
