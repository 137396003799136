import { forwardRef, ReactNode, useRef } from "react";
import { Icon, IconSource, Tooltip } from "@shopify/polaris";
import { DragHandleMinor } from "@shopify/polaris-icons";
import { Box, SystemProps, Text } from "@storyofams/react-ui";
import { Controller } from "react-hook-form";
import { SortableHandle } from "react-sortable-hoc";
import styled, { css } from "styled-components";

import { File } from "~/graphql/sdk";
import { EditorController } from "../EditorController";
import { Option, OptionProps } from "../Option";

import { ImageUpload } from "./ImageUpload";

interface OptionImageProps {
  control: any;
  field: any;
  index: number;
  src?: File;
  register: any;
  /** @default not-transparent */
  type?: "not-transparent" | "white-bg";
  optionId: string;
  flowNodeId: string;
  pageSettings?: any;
}

const StyledOption = styled(Option)<Pick<OptionImageProps, "type">>`
  background-color: transparent;
  ${(p) =>
    p.isActive &&
    css`
      && {
        ${p.type === "white-bg"
          ? css`
              background-color: white;
            `
          : css`
              border-color: ${p.theme.colors.black60};
            `}
      }
    `}
`;

const DragHandle = SortableHandle(() => (
  <Box position="absolute" top={0} left={0} zIndex={99}>
    <Tooltip content="Drag to change order" dismissOnMouseOut>
      <Box
        className="drag-handle"
        css={{
          ".Polaris-Icon": {
            width: "24px",
            height: "24px",
          },
        }}
        cursor="grab"
        p={1}
        bg="white"
        borderTopLeftRadius="8px"
        borderBottomRightRadius="8px"
        boxShadow="lg"
      >
        <Icon source={DragHandleMinor as IconSource} color="subdued" />
      </Box>
    </Tooltip>
  </Box>
));

const Answer = forwardRef(
  (
    {
      children,
      type,
      className,
    }: {
      children?: ReactNode;
      type: OptionImageProps["type"];
      className?: string;
    },
    ref
  ) => (
    <Text
      ref={ref as any}
      fontSize={2}
      lineHeight="19px"
      color={type === "white-bg" ? "black90" : "black80"}
      className={className}
    >
      {children}
    </Text>
  )
);

export const OptionImage = ({
  isActive,
  src,
  type,
  control,
  index,
  field,
  register,
  flowNodeId,
  optionId,
  pageSettings,
  ...props
}: OptionImageProps & Omit<OptionProps, "children"> & SystemProps) => {
  const editorRef = useRef<any>();

  const imageHeight = pageSettings?.imageHeight || 180;

  return (
    <StyledOption
      flexDirection="column"
      type={type}
      isTransparent={true}
      isActive={isActive}
      {...props}
    >
      <>
        <Box
          position="relative"
          height={type === "white-bg" ? "210px" : `${imageHeight}px`}
          width="100%"
          className="answer-image-container"
          css={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ImageUpload
            src={src}
            index={index}
            flowNodeId={flowNodeId}
            optionId={optionId}
            pageSettings={pageSettings}
          />
        </Box>

        <Box
          p={3}
          pt={type === "white-bg" ? 2 : 3}
          textAlign="center"
          width="100%"
        >
          <Controller
            control={control}
            name={`options.${index}.id`}
            defaultValue={field.id}
            render={(input) => <input type="hidden" {...input.field} />}
          />
          <Controller
            control={control}
            name={`options.${index}.productIds`}
            defaultValue={field.productIds}
            render={(input) => <input type="hidden" {...input.field} />}
          />
          <Controller
            control={control}
            name={`options.${index}.variantIds`}
            defaultValue={field.variantIds}
            render={(input) => <input type="hidden" {...input.field} />}
          />
          <Controller
            control={control}
            name={`options.${index}.collectionIds`}
            defaultValue={field.collectionIds}
            render={(input) => <input type="hidden" {...input.field} />}
          />

          <EditorController
            name={`options.${index}.label`}
            control={control}
            element={Answer as any}
            placeholder="Your answer here..."
            editorRef={editorRef}
            defaultValue={field.label}
            className="answer-text-with-image"
          />
        </Box>

        <DragHandle />
      </>
    </StyledOption>
  );
};
