import { useState } from "react";
import {
  Button,
  Collapsible,
  Icon,
  RangeSlider,
  Select,
  Stack,
} from "@shopify/polaris";
import { Controller, useWatch } from "react-hook-form";
import { TextField } from "@shopify/polaris";

import { Section } from "~/components/Section";
import { useOptionsForm } from "~/hooks";
import {
  DesktopMajor,
  MobileMajor,
  PaintBrushMajor,
  TemplateMajor,
} from "@shopify/polaris-icons";

import styled from "styled-components";

import { ColorInput } from "~/components/ColorInput";

const getDefaultValues = (values) => {
  const pageSettingsJSON = values?.pageSettings;
  const pageSettings = pageSettingsJSON ? JSON.parse(pageSettingsJSON) : {};

  return {
    hideNoThanks:
      typeof values?.hideNoThanks !== "undefined"
        ? !!values.hideNoThanks
        : false,
    askForName: values?.askForName || "no",
    gdprCheckbox: values?.gdprCheckbox || "no",
    gdprText:
      values?.gdprText ||
      "I agree to receive marketing communications and understand my personal data will be processed in accordance with the <a>Privacy Policy</a>.",
    gdprLink: values?.gdprLink || "https://mystore.com/privacy-policy",
    emailPage_displayEmailIcon:
      pageSettings?.emailPage_displayEmailIcon ?? "yes",
    emailPage_displayHeadlineSubheadline:
      pageSettings?.emailPage_displayHeadlineSubheadline ?? "yes",
    emailPage_buttonColor: pageSettings?.emailPage_buttonColor ?? "#000",
    emailPage_useDefaultColorForButton:
      pageSettings?.emailPage_useDefaultColorForButton ?? "yes",
    emailPage_buttonTextColor:
      pageSettings?.emailPage_buttonTextColor ?? "#fff",
    emailPage_useDefaultTextColorForButton:
      pageSettings?.emailPage_useDefaultTextColorForButton ?? "yes",
    emailPage_buttonFontSize: pageSettings?.emailPage_buttonFontSize ?? "18",
    emailPage_buttonHorizontalPadding:
      pageSettings?.emailPage_buttonHorizontalPadding ?? "56",
    emailPage_buttonVerticalPadding:
      pageSettings?.emailPage_buttonVerticalPadding ?? "20",
    emailPage_buttonBorderRadius:
      pageSettings?.emailPage_buttonBorderRadius ?? "8",
    emailPage_buttonWidthType:
      pageSettings?.emailPage_buttonWidthType ?? "100%",
    emailPage_buttonWidth: pageSettings?.emailPage_buttonWidth ?? "370",
    emailPage_TopWidth: pageSettings?.emailPage_TopWidth ?? "480",
    emailPage_BottomWidth: pageSettings?.emailPage_BottomWidth ?? "370",
    emailPage_TopWidthMobileType:
      pageSettings?.emailPage_TopWidthMobileType ?? "100%",
    emailPage_TopWidthMobile: pageSettings?.emailPage_TopWidthMobile ?? "345",
    emailPage_BottomWidthMobileType:
      pageSettings?.emailPage_BottomWidthMobileType ?? "100%",
    emailPage_BottomWidthMobile:
      pageSettings?.emailPage_BottomWidthMobile ?? "345",
    emailPage_buttonFontSizeMobile:
      pageSettings?.emailPage_buttonFontSizeMobile ?? "18",
    emailPage_buttonBorderRadiusMobile:
      pageSettings?.emailPage_buttonBorderRadiusMobile ?? "8",
    emailPage_buttonHorizontalPaddingMobile:
      pageSettings?.emailPage_buttonHorizontalPaddingMobile ?? "56",
    emailPage_buttonVerticalPaddingMobile:
      pageSettings?.emailPage_buttonVerticalPaddingMobile ?? "20",
    emailPage_buttonWidthMobileType:
      pageSettings?.emailPage_buttonWidthMobileType ?? "100%",
    emailPage_buttonWidthMobile:
      pageSettings?.emailPage_buttonWidthMobile ?? "345",
  };
};

const GeneralDesignSettings = ({ control }) => {
  const topWidthMobileType = useWatch({
    name: "emailPage_TopWidthMobileType",
    control,
  });
  const bottomWidthMobileType = useWatch({
    name: "emailPage_BottomWidthMobileType",
    control,
  });

  return (
    <>
      <Section
        title={
          (
            <Stack
              spacing="extraTight"
              alignment="center"
              wrap={false}
              distribution="center"
            >
              <Icon source={DesktopMajor as any} />
              <span>Desktop</span>
            </Stack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="emailPage_TopWidth"
          render={({ field: { value, onChange, ...field } }) => (
            <TextField
              label="Top Section Width"
              value={value}
              onChange={onChange}
              type="text"
              autoComplete="off"
              connectedLeft={
                <span
                  style={{
                    position: "relative",
                    top: "10px",
                    display: "inline-block",
                    marginRight: "5px",
                  }}
                >
                  <RangeSlider
                    label=""
                    value={value}
                    onChange={onChange}
                    min={0}
                    max={1500}
                    step={5}
                    output
                  />
                </span>
              }
              suffix="px"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="emailPage_BottomWidth"
          render={({ field: { value, onChange, ...field } }) => (
            <TextField
              label="Bottom Section Width"
              value={value}
              onChange={onChange}
              type="text"
              autoComplete="off"
              connectedLeft={
                <span
                  style={{
                    position: "relative",
                    top: "10px",
                    display: "inline-block",
                    marginRight: "5px",
                  }}
                >
                  <RangeSlider
                    label=""
                    value={value}
                    onChange={onChange}
                    min={0}
                    max={1500}
                    step={5}
                    output
                  />
                </span>
              }
              suffix="px"
              {...field}
            />
          )}
        />
      </Section>
      <Section
        title={
          (
            <Stack
              spacing="extraTight"
              alignment="center"
              wrap={false}
              distribution="center"
            >
              <Icon source={MobileMajor as any} />
              <span>Mobile</span>
            </Stack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="emailPage_TopWidthMobileType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Top Section Width"
              value={value}
              {...field}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {topWidthMobileType === "custom" && (
          <Controller
            control={control}
            name="emailPage_TopWidthMobile"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={1500}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="emailPage_BottomWidthMobileType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Bottom Section Width"
              value={value}
              {...field}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {bottomWidthMobileType === "custom" && (
          <Controller
            control={control}
            name="emailPage_BottomWidthMobile"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={1500}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
      </Section>
    </>
  );
};

const ButtonSettings = ({ control }) => {
  const useDefaultColorForButton = useWatch({
    name: "emailPage_useDefaultColorForButton",
    control,
  });
  const useDefaultTextColorForButton = useWatch({
    name: "emailPage_useDefaultTextColorForButton",
    control,
  });
  const emailPage_buttonWidthType = useWatch({
    name: "emailPage_buttonWidthType",
    control,
  });

  return (
    <Section>
      <Stack spacing="extraTight" alignment="trailing" wrap={false}>
        <Stack.Item fill>
          <Controller
            control={control}
            name="emailPage_useDefaultColorForButton"
            render={({ field: { value, onChange, ...field } }) => (
              <Select
                label="Background Color"
                value={value}
                {...field}
                options={[
                  { label: "Use default color", value: "yes" },
                  { label: "Use custom color", value: "no" },
                ]}
                onChange={onChange}
                {...field}
              />
            )}
          />
        </Stack.Item>
        {useDefaultColorForButton === "no" && (
          <Controller
            control={control}
            name="emailPage_buttonColor"
            render={({ field: { value, onChange, ...field } }) => (
              <ColorInput value={value} onChange={onChange} {...field} />
            )}
          />
        )}
      </Stack>
      <Stack spacing="extraTight" alignment="trailing" wrap={false}>
        <Stack.Item fill>
          <Controller
            control={control}
            name="emailPage_useDefaultTextColorForButton"
            render={({ field: { value, onChange, ...field } }) => (
              <Select
                label="Text Color"
                value={value}
                {...field}
                options={[
                  { label: "Use default color", value: "yes" },
                  { label: "Use custom color", value: "no" },
                ]}
                onChange={onChange}
                {...field}
              />
            )}
          />
        </Stack.Item>
        {useDefaultTextColorForButton === "no" && (
          <Controller
            control={control}
            name="emailPage_buttonTextColor"
            render={({ field: { value, onChange, ...field } }) => (
              <ColorInput value={value} onChange={onChange} {...field} />
            )}
          />
        )}
      </Stack>
      <Section
        title={
          (
            <Stack
              spacing="extraTight"
              alignment="center"
              wrap={false}
              distribution="center"
            >
              <Icon source={DesktopMajor as any} />
              <span>Desktop</span>
            </Stack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="emailPage_buttonFontSize"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Font Size"
              value={value}
              onChange={onChange}
              min={8}
              max={50}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="emailPage_buttonBorderRadius"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Border Radius"
              value={value}
              onChange={onChange}
              min={0}
              max={60}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="emailPage_buttonWidthType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Width"
              value={value}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
                { label: "Auto Width", value: "auto" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {emailPage_buttonWidthType === "custom" && (
          <Controller
            control={control}
            name="emailPage_buttonWidth"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={1500}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="emailPage_buttonHorizontalPadding"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Horizontal Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={500}
              step={5}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="emailPage_buttonVerticalPadding"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Vertical Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={150}
              step={1}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
      </Section>
      <Section
        title={
          (
            <Stack
              spacing="extraTight"
              alignment="center"
              wrap={false}
              distribution="center"
            >
              <Icon source={MobileMajor as any} />
              <span>Mobile</span>
            </Stack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="emailPage_buttonFontSizeMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Font Size"
              value={value}
              onChange={onChange}
              min={8}
              max={50}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="emailPage_buttonBorderRadiusMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Border Radius"
              value={value}
              onChange={onChange}
              min={0}
              max={60}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="emailPage_buttonWidthMobileType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Width"
              value={value}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
                { label: "Auto Width", value: "auto" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {emailPage_buttonWidthType === "custom" && (
          <Controller
            control={control}
            name="emailPage_buttonWidthMobile"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={780}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="emailPage_buttonHorizontalPaddingMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Horizontal Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={500}
              step={5}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="emailPage_buttonVerticalPaddingMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Vertical Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={150}
              step={1}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
      </Section>
    </Section>
  );
};

const ElementSettings = ({ control }) => {
  const gdprCheckbox = useWatch({ name: "gdprCheckbox", control });

  return (
    <Section>
      <Controller
        control={control}
        name="emailPage_displayEmailIcon"
        render={({ field: { value, onChange, ...field } }) => (
          <Select
            label="Email Icon"
            value={value}
            {...field}
            options={[
              { label: "Hide", value: "no" },
              { label: "Show", value: "yes" },
            ]}
            onChange={onChange}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="emailPage_displayHeadlineSubheadline"
        render={({ field: { value, onChange, ...field } }) => (
          <Select
            label="Headline & Subheadline"
            value={value}
            {...field}
            options={[
              { label: "Hide", value: "no" },
              { label: "Show", value: "yes" },
            ]}
            onChange={onChange}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="askForName"
        render={({ field: { value, ...field } }) => (
          <Select
            label="Name"
            value={value}
            {...field}
            options={[
              { label: "Hide", value: "no" },
              { label: "Show (required)", value: "required" },
              { label: "Show (optional)", value: "optional" },
            ]}
          />
        )}
      />
      <Controller
        control={control}
        name="gdprCheckbox"
        render={({ field: { value, ...field } }) => (
          <Select
            label="GDPR Checkbox"
            value={value}
            {...field}
            options={[
              { label: "Hide", value: "no" },
              { label: "Show - Mandatory (below the button)", value: "below" },
              { label: "Show - Mandatory (above the button)", value: "above" },
              {
                label: "Show - Optional (below the button)",
                value: "below-optional",
              },
              {
                label: "Show - Optional (above the button)",
                value: "above-optional",
              },
            ]}
          />
        )}
      />
      {gdprCheckbox !== "no" && (
        <Controller
          control={control}
          name="gdprText"
          render={({ field: { value, ...field } }) => (
            <TextField
              multiline={3}
              autoComplete="false"
              label="GDPR Text"
              value={value}
              {...field}
            />
          )}
        />
      )}
      {gdprCheckbox !== "no" && (
        <Controller
          control={control}
          name="gdprLink"
          render={({ field: { value, ...field } }) => (
            <TextField
              autoComplete="false"
              label="GDPR Link"
              value={value}
              {...field}
            />
          )}
        />
      )}
      <Controller
        control={control}
        name="hideNoThanks"
        render={({ field: { value, onChange, ...field } }) => (
          <Select
            label="No Thanks / Opt-In Skip Link"
            value={value === false ? "show" : "hide"}
            onChange={(v) => {
              onChange(v === "hide");
            }}
            options={[
              { label: "Show", value: "show" },
              { label: "Hide", value: "hide" },
            ]}
            {...field}
          />
        )}
      />
    </Section>
  );
};

export const EmailOptions = () => {
  const [settings, setSettings] = useState(0);

  const { control } = useOptionsForm({
    getDefaultValues,
    formatValues: (values) => ({
      ...values,
    }),
    pageSettingsKeys: [
      "emailPage_buttonColor",
      "emailPage_useDefaultColorForButton",
      "emailPage_displayEmailIcon",
      "emailPage_displayHeadlineSubheadline",
      "emailPage_useDefaultTextColorForButton",
      "emailPage_buttonTextColor",
      "emailPage_buttonFontSize",
      "emailPage_buttonHorizontalPadding",
      "emailPage_buttonVerticalPadding",
      "emailPage_buttonBorderRadius",
      "emailPage_buttonWidthType",
      "emailPage_buttonWidth",
      "emailPage_TopWidth",
      "emailPage_BottomWidth",
      "emailPage_TopWidthMobileType",
      "emailPage_TopWidthMobile",
      "emailPage_BottomWidthMobileType",
      "emailPage_BottomWidthMobile",
      "emailPage_buttonFontSizeMobile",
      "emailPage_buttonBorderRadiusMobile",
      "emailPage_buttonHorizontalPaddingMobile",
      "emailPage_buttonVerticalPaddingMobile",
      "emailPage_buttonWidthMobileType",
      "emailPage_buttonWidthMobile",
    ],
    type: "flow",
  });

  return (
    <SettingsContainer>
      <Stack vertical distribution="leading" alignment="fill">
        <Button
          plain
          fullWidth
          textAlign="left"
          destructive={settings === 1}
          disclosure={settings === 1 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 1 ? 0 : 1))}
          icon={TemplateMajor as any}
        >
          Show/Hide Page Elements
        </Button>
        <Collapsible
          open={settings === 1}
          id="collapsible-1"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <ElementSettings control={control} />
        </Collapsible>
        <Button
          plain
          fullWidth
          textAlign="left"
          destructive={settings === 2}
          disclosure={settings === 2 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 2 ? 0 : 2))}
          icon={PaintBrushMajor as any}
        >
          General Design Settings
        </Button>
        <Collapsible
          open={settings === 2}
          id="collapsible-2"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <GeneralDesignSettings control={control} />
        </Collapsible>
        <Button
          plain
          fullWidth
          textAlign="left"
          destructive={settings === 3}
          disclosure={settings === 3 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 3 ? 0 : 3))}
          icon={PaintBrushMajor as any}
        >
          Button Design Settings
        </Button>
        <Collapsible
          open={settings === 3}
          id="collapsible-3"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <ButtonSettings control={control} />
        </Collapsible>
      </Stack>
    </SettingsContainer>
  );
};

const SettingsContainer = styled.div`
  padding: 15px 5px;

  & .Polaris-Button__Content {
    width: 100%;
    justify-content: space-between !important;
  }

  & .Polaris-Button__Icon {
    margin-left: 0 !important;
  }
`;
