import { useMemo } from "react";
import { List, Stack, TextStyle } from "@shopify/polaris";
import {
  EmailMajor,
  HomeMajor,
  ImportStoreMajor,
  TransferMajor,
} from "@shopify/polaris-icons";
import { Flex } from "@storyofams/react-ui";
import dagre from "dagre";
import { uniqWith } from "lodash";
import ReactFlow, {
  ReactFlowProvider,
  Controls,
  isNode,
  Elements,
  FlowElement,
} from "react-flow-renderer";

import {
  FlowFragmentFragment,
  FlowNodeOptionNextAction,
  FlowNodeType,
} from "~/graphql/sdk";

import { Edge } from "./Edge";
import { NodeLabel } from "./NodeLabel";
import { StyledFlow } from "./StyledFlow";

const nodeWidth = 150;
const nodeHeight = 54;

const getPositionedElements = (dagreGraph: any, elements: Elements) => {
  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
    } else {
      dagreGraph.setEdge(el.source, el.target, { minlen: 2 });
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el) => {
    if (isNode(el)) {
      el.position = { x: 0, y: 0 };

      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = "left" as any;
      el.sourcePosition = "right" as any;

      // unfortunately we need this little hack to pass a slightly different position
      // to notify react flow about the change. Moreover we are shifting the dagre node position
      // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
      el.position = {
        x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
    }

    return el;
  });
};

interface LogicPreviewProps {
  flow: FlowFragmentFragment;
  setModalActive(active: string | boolean): void;
}

export const LogicPreview = ({ flow, setModalActive }: LogicPreviewProps) => {
  const elements = useMemo(() => {
    const unpositionedElements: any[] = [];

    // check if last node is an email node
    const isLastNodeEmailNode =
      flow?.nodes?.[flow?.nodes?.length - 1]?.type === FlowNodeType.Email;
    let lastNodeId = flow?.nodes?.[flow?.nodes?.length - 1]?.id;

    // remap question numbers
    // transition screens are not counted
    // welcome screen is not counted
    const questionNumbers = {} as { [key: number]: string };
    let lastQuestionNumber = 0;
    flow.nodes?.map((question, idx) => {
      if (question.type === FlowNodeType.Welcome) {
        questionNumbers[idx] = "";
      } else if (question.type === FlowNodeType.Transition) {
        questionNumbers[idx] = "";
      } else if (question.type === FlowNodeType.Email) {
        questionNumbers[idx] = "";
      } else {
        questionNumbers[idx] = `${++lastQuestionNumber}.`;
      }
    });

    flow.nodes.forEach((node, idx) => {
      // add nodes
      unpositionedElements.push({
        id: node.id,
        data: {
          label:
            node.type === FlowNodeType.Welcome ? (
              <NodeLabel icon={HomeMajor} text="Welcome screen" />
            ) : node.type === FlowNodeType.Email ? (
              <NodeLabel icon={EmailMajor} text="Email capture" />
            ) : node.type === FlowNodeType.Transition ? (
              <NodeLabel icon={TransferMajor} text="Transition screen" />
            ) : (
              `${
                questionNumbers[idx]
                  ? `${questionNumbers[idx]} ${node.title}`
                  : "Untitled"
              }`
            ),
        },
        type: idx === 0 ? "input" : "default",
      });

      // add edge from welcome screen to next node
      if (node.type === FlowNodeType.Welcome && flow.nodes?.[idx + 1]) {
        unpositionedElements.push({
          id: `edge-welcome`,
          type: "edge",
          arrowHeadType: "arrow",
          source: node.id,
          target: flow.nodes[idx + 1].id,
          data: {
            isDefault: true,
          },
        });
      }

      // add edge from email capture page to next node
      if (
        node.type === FlowNodeType.Email &&
        (!node.nextNode || node.nextNode === "auto")
      ) {
        unpositionedElements.push({
          id: `edge-email`,
          type: "edge",
          arrowHeadType: "arrow",
          source: node.id,
          target: flow.nodes?.[idx + 1]?.id ?? "results",
          data: {
            isDefault: true,
          },
        });
      }

      const uniqueEdges = uniqWith(
        node.options,
        (a, b) => a.nextAction === b.nextAction && a.nextNode === b.nextNode
      );

      if (
        !node.isRequired &&
        !uniqueEdges.find(
          (edge) => edge.nextAction === FlowNodeOptionNextAction.Auto
        )
      ) {
        uniqueEdges.push({ nextAction: FlowNodeOptionNextAction.Auto } as any);
      }

      // add edges for pages with nextNode set
      if (node.nextNode && node.nextNode !== "auto") {
        unpositionedElements.push({
          id: `edge-${node.id}-transition`,
          type: "edge",
          arrowHeadType: "arrow",
          source: node.id,
          target: node.nextNode,
          data: {
            isDefault: false,
            popoverText: node.nextNode ? (
              <Stack vertical spacing="tight">
                <Stack.Item>Jump to</Stack.Item>
                <List type="bullet">
                  <List.Item>
                    <TextStyle variation="strong">
                      {node.nextNode === "results"
                        ? "Results"
                        : `${
                            questionNumbers[
                              flow.nodes.findIndex(
                                ({ id }) => id === node.nextNode
                              )
                            ]
                              ? `${
                                  questionNumbers[
                                    flow.nodes.findIndex(
                                      ({ id }) => id === node.nextNode
                                    )
                                  ]
                                } `
                              : ""
                          }${
                            flow.nodes.find(({ id }) => id === node.nextNode)
                              ?.type === FlowNodeType.Transition
                              ? `Transition Screen - ${
                                  flow.nodes.find(
                                    ({ id }) => id === node.nextNode
                                  )?.title || "Untitled"
                                }`
                              : flow.nodes.find(
                                  ({ id }) => id === node.nextNode
                                )?.type === FlowNodeType.Email
                              ? `Email Capture Page`
                              : flow.nodes.find(
                                  ({ id }) => id === node.nextNode
                                )?.title || "Untitled"
                          }`}
                    </TextStyle>
                  </List.Item>
                </List>
              </Stack>
            ) : null,
            toggleActive: () => {
              setModalActive(node.id);
            },
          },
        });
      }

      // add edges for elements without nextNode set that are non-option screens
      if (
        node.type === FlowNodeType.Transition ||
        node.type === FlowNodeType.Email ||
        node.type === FlowNodeType.Welcome ||
        node.type === FlowNodeType.InputCalendar ||
        node.type === FlowNodeType.InputMultiLineText ||
        node.type === FlowNodeType.InputOneLineText ||
        node.type === FlowNodeType.InputSlider
      ) {
        if (!node.nextNode || node.nextNode === "auto") {
          const nextAction = FlowNodeOptionNextAction.Auto;
          const nextNode = null;
          unpositionedElements.push({
            id: `edge-${node.id}-${nextAction}-${nextNode}`,
            type: "edge",
            arrowHeadType: "arrow",
            source: node.id,
            target:
              idx >= flow.nodes.length - 1 ? "results" : flow.nodes[idx + 1].id,

            data: {
              isDefault: true,
            },
          });
        }
      }

      // add edge labels for edges with logic jumps
      uniqueEdges.forEach(({ nextAction, nextNode }) => {
        const optionsForEdge =
          node.options?.filter(
            (option) =>
              option.nextAction === nextAction && option.nextNode === nextNode
          ) || [];

        // skip nodes that have node.nextNode set
        if (node.nextNode && node.nextNode !== "auto") {
          return;
        }

        // if (
        //   (!node.nextNode || node.nextNode === "auto") &&
        //   nextAction === FlowNodeOptionNextAction.Auto &&
        //   nextNode === null
        // ) {
        //   return;
        // }

        // add edges for answer-level logic jumps
        unpositionedElements.push({
          id: `edge-${node.id}-${nextAction}-${nextNode}`,
          type: "edge",
          arrowHeadType: "arrow",
          source: node.id,
          target:
            nextAction === FlowNodeOptionNextAction.Auto ||
            (nextAction === FlowNodeOptionNextAction.SpecificNode && !nextNode)
              ? idx >= flow.nodes.length - 1
                ? "results"
                : flow.nodes[idx + 1].id
              : nextAction === FlowNodeOptionNextAction.EndSession
              ? isLastNodeEmailNode && node.type !== FlowNodeType.Email
                ? lastNodeId
                : "results"
              : nextNode,
          data: {
            isDefault: nextAction === FlowNodeOptionNextAction.Auto,
            popoverText:
              nextAction !== FlowNodeOptionNextAction.Auto ? (
                <Stack vertical spacing="tight">
                  <Stack.Item>If answer equals</Stack.Item>
                  <List type="bullet">
                    {optionsForEdge.map((option) => {
                      return (
                        <List.Item key={option.id}>
                          <TextStyle variation="strong">
                            {(node.options?.findIndex(
                              ({ id }) => id === option.id
                            ) || 0) + 1}
                            . {option?.label || "Untitled"}
                          </TextStyle>
                        </List.Item>
                      );
                    })}
                  </List>
                  <Stack.Item>Jump to</Stack.Item>
                  <List type="bullet">
                    <List.Item>
                      <TextStyle variation="strong">
                        {nextAction === FlowNodeOptionNextAction.EndSession
                          ? isLastNodeEmailNode &&
                            node.type !== FlowNodeType.Email
                            ? "Email Capture Page"
                            : "Results"
                          : `${
                              questionNumbers[
                                flow.nodes.findIndex(
                                  ({ id }) => id === nextNode
                                )
                              ]
                                ? `${
                                    questionNumbers[
                                      flow.nodes.findIndex(
                                        ({ id }) => id === nextNode
                                      )
                                    ]
                                  } `
                                : ""
                            }${
                              flow.nodes.find(({ id }) => id === nextNode)
                                ?.type === FlowNodeType.Transition
                                ? `Transition Screen - ${
                                    flow.nodes.find(({ id }) => id === nextNode)
                                      ?.title || "Untitled"
                                  }`
                                : flow.nodes.find(({ id }) => id === nextNode)
                                    ?.type === FlowNodeType.Email
                                ? `Email Capture Page`
                                : flow.nodes.find(({ id }) => id === nextNode)
                                    ?.title || "Untitled"
                            }`}
                      </TextStyle>
                    </List.Item>
                  </List>
                </Stack>
              ) : null,
            toggleActive: () => {
              setModalActive(node.id);
            },
          },
        });
      });
    });

    // add results node
    unpositionedElements.push({
      id: "results",
      data: {
        label: <NodeLabel icon={ImportStoreMajor} text="Results" />,
      },
      type: "output",
    });

    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({
      rankdir: "LR",
      nodesep: 100,
      edgesep: 50,
    });

    return getPositionedElements(dagreGraph, unpositionedElements);
  }, [flow]);

  const onLoad = (reactFlowInstance) => {
    reactFlowInstance.fitView();
  };

  const onElementClick = (_, element: FlowElement) => {
    if (element.type !== "edge" && !["results"].includes(element.id)) {
      setModalActive(element.id);
    }
  };

  const edgeTypes = {
    edge: Edge,
  };

  return (
    <Flex
      flexDirection="column"
      flex="1"
      borderBottomLeftRadius="md"
      borderBottomRightRadius="md"
      boxShadow="0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25)"
      mb={2}
    >
      <StyledFlow>
        {/* @ts-ignore */}
        <ReactFlowProvider>
          {/* @ts-ignore */}
          <ReactFlow
            elements={elements}
            nodesConnectable={false}
            elementsSelectable={false}
            onElementClick={onElementClick}
            onLoad={onLoad}
            edgeTypes={edgeTypes as any}
            maxZoom={1.5}
          >
            <Controls showInteractive={false} />
          </ReactFlow>
        </ReactFlowProvider>
      </StyledFlow>
    </Flex>
  );
};
