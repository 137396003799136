import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Card,
  Spinner,
  DisplayText,
  Button,
  Stack,
  Icon,
  Link,
  TextStyle,
  Page,
  Thumbnail,
  ResourceItem,
  ResourceList,
  Filters,
  EmptySearchResult,
  IconSource,
} from "@shopify/polaris";
import {
  CancelSmallMinor,
  CircleInformationMajor,
  ImageMajor,
} from "@shopify/polaris-icons";
import { Box, Flex } from "@storyofams/react-ui";
import { debounce, difference } from "lodash";
import { useQuery, useQueryClient } from "react-query";

import { ProductsModal } from "~/components";
import { useFlow, useSdk } from "~/hooks";
import { getShopifyImage, useToast } from "~/lib";
import { ErrorBanner } from "../ErrorBanner";

const resourceName = {
  singular: "product",
  plural: "products",
};

const normalizeString = (value: string) =>
  value
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();

export const ProductsPage = () => {
  const { data: flowContainer } = useFlow();
  const queryClient = useQueryClient();
  const sdk = useSdk();
  const toast = useToast();

  const flow = flowContainer?.flows?.[0];

  const [isBusy, setBusy] = useState(false);
  const [active, setActive] = useState(false);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [queryValue, setQueryValue] = useState("");
  const [search, setSearch] = useState("");

  //https://shopify.dev/api/usage/rate-limits
  const SHOPIFY_MAX_ARRAY_SIZE = 250;

  const setSearchDebounced = useCallback(
    debounce(setSearch, 200, { trailing: true, maxWait: 600 }),
    [setSearch]
  );

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const { isLoading, data, error, isFetching } = useQuery(
    ["productsOverview", { ids: flow?.productIds }],
    async () => {
      //Abide by shopify's api rate limit

      //1. Split array into chunks of 250
      let productChunks: any[] = [];
      let productIdsCopy: any[] = [...(flow?.productIds as String[])];
      let numProducts = flow?.productIds?.length || 0;
      let totalChunks = Math.ceil(numProducts / SHOPIFY_MAX_ARRAY_SIZE);
      for (let i = totalChunks; i > 0; i--) {
        productChunks.push(
          productIdsCopy.splice(0, Math.ceil(numProducts / i))
        );
      }

      //2. Call shopify api
      let products: any[] = [];

      //We use a while loop because forEach & map are more difficult for async calls
      let i = 0;
      while (i < productChunks.length) {
        const results = await sdk
          .shopifyProductsByIds({
            input: { ids: productChunks[i] || [], locale: "en" },
          })
          .then((res) => res.shopifyProductsByIds);
        products.push(...results);
        i++;
      }

      //3. Return single list
      return products;
    }
  );

  useEffect(() => {
    if (search !== queryValue) {
      setSearchDebounced(queryValue);

      if (selectedItems?.length) {
        setSelectedItems([]);
      }
    }
  }, [queryValue]);

  const toggleSelected = useCallback(
    (id: string) => {
      const idx = selectedItems?.indexOf(id);

      if (idx !== -1) {
        setSelectedItems([...selectedItems?.filter((item) => item !== id)]);
      } else {
        setSelectedItems([...selectedItems, id]);
      }
    },
    [selectedItems, setSelectedItems]
  );

  const removeProducts = async (products: string[]) => {
    if (!products?.length) {
      toast({ content: "No product(s) selected" });
      return;
    }

    if (isBusy || !flow?.id || !flowContainer?.id) {
      return;
    }

    setBusy(true);

    try {
      const productIds = difference(flow.productIds, products);

      const result = await sdk.updateOneFlow({
        input: {
          id: flow.id,
          update: {
            productIds,
          },
        },
      });

      queryClient.setQueryData(
        ["container", { id: flowContainer.id }],
        (old: any) => ({
          ...old,
          flows: [
            result.updateOneFlow,
            ...(old?.flows?.length > 1 ? old.flows.slice(1) : []),
          ],
        })
      );

      toast({
        content: `Product${products.length > 1 ? "s" : ""} removed from flow`,
      });
      setSelectedItems([]);
    } catch (e: any) {
      toast({
        error: true,
        content:
          e?.messages?.[0] ||
          e?.message ||
          `Error removing product${products.length > 1 ? "s" : ""}`,
      });
    }

    setBusy(false);
  };

  const renderItem = useCallback(
    (item) => {
      const { id, featuredImage, title } = item;
      const media = (
        <Thumbnail
          size="small"
          alt=""
          source={
            featuredImage?.transformedSrc
              ? getShopifyImage(featuredImage?.transformedSrc, "80x80")
              : ImageMajor
          }
        />
      );

      return (
        <ResourceItem
          id={id}
          onClick={() => {
            toggleSelected(id);
          }}
          verticalAlignment="center"
          media={media}
          accessibilityLabel={`Select ${title}`}
          persistActions
          shortcutActions={[
            {
              // @ts-ignore
              content: (
                <Button
                  icon={CancelSmallMinor as IconSource}
                  plain
                  accessibilityLabel="Remove from flow"
                />
              ),
              onAction: () => {
                removeProducts([id]);
              },
              disabled: isBusy,
            },
          ]}
        >
          <TextStyle>{title}</TextStyle>
        </ResourceItem>
      );
    },
    [toggleSelected]
  );

  const emptyStateMarkup = useMemo(() => {
    let content;

    if (error) {
      content = (
        <Box px={2}>
          <ErrorBanner error={error} />
        </Box>
      );
    } else {
      content = (
        <EmptySearchResult
          title="No products found"
          description="Try changing the filters or search term"
          withIllustration
        />
      );
    }

    return (
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        width="100%"
        minHeight="200px"
      >
        {content}
      </Flex>
    );
  }, [error, data]);

  const filteredData = useMemo(() => {
    if (!search) {
      return data || [];
    }

    return (
      data?.filter(({ title }) =>
        normalizeString(title).includes(normalizeString(search))
      ) || []
    );
  }, [search, data]);

  if (isLoading) {
    return (
      <Page title="Linked products">
        <Card sectioned>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flex="1"
            minWidth="0"
            width="100%"
            height="100%"
            minHeight="400px"
          >
            {"Loading " + (flow?.productIds.length || 0) + " products"}
            <Spinner />
          </Flex>
        </Card>
      </Page>
    );
  }

  if (!data?.length) {
    return (
      <Page title="Linked products">
        <Card sectioned>
          <Stack vertical alignment="center" spacing="extraLoose">
            <img
              alt=""
              src={`${process.env.PUBLIC_URL}/images/match-products.png`}
              width="163"
            />

            <Stack vertical alignment="center" spacing="tight">
              <DisplayText>
                <TextStyle variation="strong">
                  Start linking your products
                </TextStyle>
              </DisplayText>
              <TextStyle variation="subdued">
                Link products to the flow.
              </TextStyle>
            </Stack>

            <Button onClick={toggleActive} primary>
              Link products
            </Button>
          </Stack>
        </Card>

        {!!flow && (
          <ProductsModal
            active={active}
            toggleActive={toggleActive}
            flow={flow}
          />
        )}
      </Page>
    );
  }

  return (
    <>
      <Page
        title="Linked products"
        primaryAction={{
          content: "Change selection",
          onAction: toggleActive,
        }}
      >
        <Card>
          <ResourceList
            resourceName={resourceName}
            items={error ? [] : filteredData}
            loading={isFetching}
            renderItem={renderItem}
            emptyState={emptyStateMarkup}
            selectedItems={selectedItems}
            onSelectionChange={setSelectedItems}
            selectable
            filterControl={
              <Filters
                queryPlaceholder="Search products..."
                queryValue={queryValue}
                filters={[]}
                appliedFilters={[]}
                onQueryChange={(v) => {
                  setQueryValue(v);
                }}
                onQueryClear={() => {
                  setQueryValue("");
                }}
                onClearAll={() => {
                  setQueryValue("");
                }}
              />
            }
            promotedBulkActions={[
              {
                content: "Remove from flow",
                onAction: () => {
                  removeProducts(selectedItems);
                },
                disabled: isBusy,
              },
            ]}
          />
        </Card>

        <Flex pt={3} pb={3} alignItems="center" justifyContent="center">
          <Stack spacing="tight">
            <Icon
              source={CircleInformationMajor as IconSource}
              color="highlight"
            />
            <TextStyle variation="subdued">
              Learn more about{" "}
              <Link
                external
                url="https://www.trylantern.com/docs/l/understanding-quiz-logic-and-product-linking"
              >
                linking products
              </Link>
            </TextStyle>
          </Stack>
        </Flex>
      </Page>

      {!!flow && (
        <ProductsModal
          active={active}
          toggleActive={toggleActive}
          flow={flow}
          edit
        />
      )}
    </>
  );
};
