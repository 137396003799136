import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Banner,
  Modal,
  Link,
  Stack,
  TextStyle,
  Button as PolarisButton,
  Badge,
  Tooltip,
  Button,
  TextField,
  Checkbox,
  Select,
  // DropZone,
  // ProgressBar,
  Thumbnail,
  IconSource,
  RangeSlider,
  Spinner,
  Pagination,
} from "@shopify/polaris";
import { Box } from "@storyofams/react-ui";
import {
  PlusMinor,
  EditMinor,
  AddMajor,
  CancelSmallMinor,
  ProductsMinor,
  VariantMajor,
  CollectionsMajor,
  DuplicateMinor,
} from "@shopify/polaris-icons";
import styled from "styled-components";
import RichTextEditor from "~/components/preview/RichTextEditor";
import MatchAnswersModal from "~/components/preview/MatchAnswersModal";
import styles from "./styles.module.scss";
import { MediaModal } from "~/components/MediaInput/MediaModal";
import { useSdk } from "~/hooks";

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
async function sleep(seconds, fn, ...args) {
  await timeout(seconds * 1000);
  return fn(...args);
}

const UPLOAD_API_KEY = "public_FW25bYGAgB45TKGbWWuMMhPy32FL";
const PAGE_SIZE = 25;

// @ts-ignore
const upload = Upload({ apiKey: UPLOAD_API_KEY });

interface Variant {
  id: string;
  content: string;
  answers: any[];
  answersWeight: any;
  image: any;
  productRecommendations?: any;
}

const ContentBlockModal = ({
  open,
  onClose,
  onOpen,
  nodes,
  location,
  id,
  save,
  contentBlocks,
}) => {
  const [answerMatching, setAnswerMatching] = useState(false);
  const [variants, setVariants] = useState([
    {
      id: uuidv4(),
      content: "",
      answers: [] as any[],
      answersWeight: {},
      image: {} as any,
      productRecommendations: {} as any,
    } as Variant,
  ]);
  const [desktopWidth, setDesktopWidth] = useState("900px");
  const [mobileWidth, setMobileWidth] = useState("100%");
  const [targetVariant, setTargetVariant] = useState(null);
  const [showFilePicker, setShowFilePicker] = useState(false);
  const [targetVariantForUpload, setTargetVariantForUpload] = useState(null);
  const [
    targetVariantForProductRecommendations,
    setTargetVariantForProductRecommendations,
  ] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  // const [progress, setProgress] = useState(0);

  const [throttlingModalMessage, setThrottlingModalMessage] = useState(
    null as string | null
  );
  const [showThrottlingModal, setShowThrottlingModal] = useState(false);
  const [page, setPage] = useState(1);

  const sdk = useSdk();

  useEffect(() => {
    const messageHandler = async (event) => {
      if (
        event?.data?.type === "addProducts" &&
        event?.data?.target === `productBlock`
      ) {
        if (event.data.payload) {
          const result = event.data.payload;
          const selection = result ?? [];
          setVariants((variants) =>
            variants?.map((variant) => {
              if (variant.id === targetVariantForProductRecommendations) {
                return {
                  ...variant,
                  productRecommendations: {
                    ...variant?.productRecommendations,
                    selectedProducts: selection,
                  },
                };
              }
              return variant;
            })
          );
        }
      } else if (
        event?.data?.type === "addVariants" &&
        event?.data?.target === `productBlock`
      ) {
        if (event.data.payload) {
          const result = event.data.payload;
          const selection = result ?? [];
          setVariants((variants) =>
            variants?.map((variant) => {
              if (variant.id === targetVariantForProductRecommendations) {
                return {
                  ...variant,
                  productRecommendations: {
                    ...variant?.productRecommendations,
                    selectedVariants: selection,
                  },
                };
              }
              return variant;
            })
          );
        }
      } else if (
        event?.data?.type === "addCollections" &&
        event?.data?.target === `productBlock`
      ) {
        if (event.data.payload) {
          const result = event.data.payload;
          const selection = result ?? [];
          const collectionIds = selection.map((item) =>
            JSON.stringify({
              id: item.id,
              title: item.title,
              handle: item.handle,
              productsCount: item.productsCount,
              image: item.image,
            })
          );
          await addCollections(collectionIds);
          setVariants((variants) =>
            variants?.map((variant) => {
              if (variant.id === targetVariantForProductRecommendations) {
                return {
                  ...variant,
                  productRecommendations: {
                    ...variant?.productRecommendations,
                    selectedCollections: selection,
                  },
                };
              }
              return variant;
            })
          );
        }
      } else {
      }
    };

    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, [targetVariantForProductRecommendations]);

  useEffect(() => {
    if (id && contentBlocks?.[location]?.length) {
      const contentBlock = contentBlocks?.[location]?.find(
        (cb) => cb?.id === id
      );
      if (contentBlock?.variants?.length) {
        setVariants(contentBlock?.variants);
      }
      if (contentBlock?.settings?.desktopWidth) {
        setDesktopWidth(contentBlock?.settings?.desktopWidth);
      }
      if (contentBlock?.settings?.mobileWidth) {
        setMobileWidth(contentBlock?.settings?.mobileWidth);
      }
    } else {
      setVariants([
        {
          id: uuidv4(),
          content: "",
          answers: [] as any[],
          answersWeight: {},
          image: {},
          productRecommendations: {} as any,
        },
      ]);
      setDesktopWidth("900px");
      setMobileWidth("100%");
    }
  }, [contentBlocks, id]);

  const addCollections = async (collections) => {
    for (const json of collections) {
      const collection = JSON.parse(json);
      const id = collection?.id;
      if (!id) {
        continue;
      }
      const data = await sdk.collectionExists({
        input: { collectionId: id },
      });
      if (!data?.collectionExists?.exists) {
        // request product IDs from shopify
        const { shopifyProductsByCollectionId: products } =
          await sdk.shopifyProductsByCollectionId({
            input: { id },
          });

        // show a blocking popup for enough time
        // to cover the shopify api call
        // number of products / 50 * 1 second (rounded up)
        const seconds = Math.ceil(products.length / 50);
        if (products.length >= 250) {
          setThrottlingModalMessage(
            "Please note that due to Shopify API limits we can only link 250 products from each collection."
          );
        } else {
          setThrottlingModalMessage(null);
        }

        if (seconds > 0) {
          setShowThrottlingModal(true);
          await sleep(seconds, () => {
            setShowThrottlingModal(false);
          });
        }

        await sdk.createCollection({
          input: {
            collectionId: id,
            products,
          },
        });
      }
    }
  };

  const openFilePicker = (variantId) => {
    setTargetVariantForUpload(variantId);
    setShowFilePicker(true);
  };

  const closeFilePicker = () => {
    setTargetVariantForUpload(null);
    setShowFilePicker(false);
  };

  // const onProgress = ({ progress }) => {
  //   setProgress(progress);
  // };

  const uploadFile = async (file) => {
    try {
      const fileName = file?.name ?? "image";

      const { fileUrl } = await upload.uploadFile(file, {
        path: {
          // See path variables: https://upload.io/docs/path-variables
          folderPath: "/uploads/ppf",
          fileName: "{UNIQUE_DIGITS_8}{ORIGINAL_FILE_EXT}",
        },
        onProgress: () => {},
      });
      setVariants((variants) =>
        variants?.map((variant) => {
          if (variant.id === targetVariantForUpload) {
            return {
              ...variant,
              image: {
                ...variant?.image,
                name: fileName,
                url: fileUrl,
              },
            };
          }
          return variant;
        })
      );
    } catch (error) {
      console.log({ error });
    }
  };

  const setFile = (name, url) => {
    setVariants((variants) =>
      variants?.map((variant) => {
        if (variant.id === targetVariantForUpload) {
          return {
            ...variant,
            image: {
              ...variant?.image,
              name,
              url,
            },
          };
        }
        return variant;
      })
    );
    closeFilePicker();
  };

  const handleDropZoneDrop = async (
    _dropFiles,
    acceptedFiles,
    _rejectedFiles
  ) => {
    const file = acceptedFiles?.[0];
    if (file) {
      setUploading(true);
      await uploadFile(file);
      setUploading(false);
    }
    closeFilePicker();
  };

  const showAnswerMatching = (variantId) => {
    setTargetVariant(variantId);
    setAnswerMatching(true);
  };

  const hideAnswerMatching = () => {
    setTargetVariant(null);
    setAnswerMatching(false);
  };

  const addAnswers = (selectedAnswers, selectedAnswersWeight) => {
    setVariants((variants) =>
      variants?.map((variant) => {
        if (variant.id === targetVariant) {
          return {
            ...variant,
            answers: selectedAnswers,
            answersWeight: selectedAnswersWeight,
          };
        }
        return variant;
      })
    );
  };

  const saveContentBlock = async () => {
    setLoading(true);
    if (!id) {
      id = uuidv4();
    }
    await save(location, id, {
      id,
      variants,
      settings: {
        desktopWidth,
        mobileWidth,
      },
    });
    onClose();
    setLoading(false);
  };

  const addMoreVariants = () => {
    setVariants((variants) => [
      ...variants,
      { id: uuidv4(), content: "", answers: [], answersWeight: {}, image: {} },
    ]);
  };

  const cloneVariant = (id) => {
    const variant = variants.find((v) => v.id === id);
    setVariants((variants) => [
      ...variants,
      {
        ...variant,
        id: uuidv4(),
      } as Variant,
    ]);
  };

  const removeVariant = (id) => {
    setVariants(variants.filter((variant) => variant.id !== id));
  };

  const updateVariantContent = (id, content) => {
    setVariants((variants) =>
      variants?.map((variant) => {
        if (variant.id === id) {
          return {
            ...variant,
            content,
          };
        }
        return variant;
      })
    );
  };

  return (
    <>
      <Modal
        open={open && !answerMatching && !showFilePicker}
        onClose={onClose}
        title="Add a Dynamic Content Block"
        primaryAction={{
          content: "Save Content Block",
          onAction: saveContentBlock,
          loading,
          disabled: variants?.some(
            (v) =>
              !v?.content &&
              !v?.image?.showImage &&
              !v?.productRecommendations?.show
          ),
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: onClose,
          },
        ]}
        large
      >
        <Modal.Section>
          <Banner status="info" title="How dynamic content blocks work">
            <p>
              A dynamic content block can have one or more variants. Each answer
              in the quiz is a vote/match for a variant. The variant with the
              most matches is shown.
            </p>
            <br />
            <p style={{ fontStyle: "italic" }}>
              e.g. In a quiz for skin care products, two competing content
              variants could be "You have oily skin" and "You have dry skin" -
              depending on the answers provided by the customer, only the best
              matching sentence will be displayed.
            </p>
            <br />
            <p>
              If you need more help, here is a{" "}
              <Link external url="https://youtu.be/OG0j76PZJdI">
                short video
              </Link>{" "}
              demonstrating how content blocks work.
            </p>
          </Banner>
          <SettingsContainer>
            <TextField
              label="Desktop Width"
              value={desktopWidth}
              onChange={(v) => setDesktopWidth(v)}
              autoComplete="off"
            />
            <TextField
              label="Mobile Width"
              value={mobileWidth}
              onChange={(v) => setMobileWidth(v)}
              autoComplete="off"
            />
          </SettingsContainer>
          <Stack vertical spacing="loose">
            {variants
              ?.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)
              ?.map((variant, idx) => {
                const variantNumber = PAGE_SIZE * (page - 1) + idx + 1;
                return (
                  <Box mt={2} mb={2} key={variant?.id}>
                    <Stack vertical>
                      <Stack alignment="baseline">
                        <TextStyle variation="strong">
                          Variant #{variantNumber}
                        </TextStyle>
                        {variants.length > 1 && (
                          <PolarisButton
                            size="slim"
                            plain
                            destructive
                            onClick={() => removeVariant(variant?.id)}
                          >
                            Remove
                          </PolarisButton>
                        )}
                      </Stack>
                      <ImageSettingsContainer>
                        <HorizontalSettingsContainer>
                          <Checkbox
                            label="Include an image"
                            checked={variant?.image?.showImage}
                            onChange={(v) =>
                              setVariants(
                                variants?.map((v) => {
                                  if (v?.id === variant?.id) {
                                    return {
                                      ...v,
                                      image: {
                                        ...v?.image,
                                        showImage: v?.image?.showImage
                                          ? false
                                          : true,
                                      },
                                    };
                                  }
                                  return v;
                                })
                              )
                            }
                          />
                          {!!variant?.image?.showImage &&
                            !variant?.image?.name && (
                              <Button
                                onClick={() => openFilePicker(variant?.id)}
                                plain
                                size="slim"
                              >
                                Upload Image
                              </Button>
                            )}
                          {!!variant?.image?.showImage &&
                            !!variant?.image?.name &&
                            !!variant?.image?.url && (
                              <ImageNameContainer>
                                <Thumbnail
                                  source={variant?.image?.url}
                                  alt={variant?.image?.name}
                                  size="small"
                                />
                                <ImageName>{variant?.image?.name}</ImageName>
                                <Button
                                  plain
                                  size="slim"
                                  destructive
                                  onClick={() =>
                                    setVariants(
                                      variants?.map((v) => {
                                        if (v?.id === variant?.id) {
                                          return {
                                            ...v,
                                            image: {
                                              ...v?.image,
                                              name: null,
                                              url: null,
                                            },
                                          };
                                        }
                                        return v;
                                      })
                                    )
                                  }
                                >
                                  Remove
                                </Button>
                              </ImageNameContainer>
                            )}
                        </HorizontalSettingsContainer>
                        {!!variant?.image?.showImage && (
                          <HorizontalSettingsContainer>
                            <TextField
                              label="Image Width (Desktop)"
                              value={variant?.image?.width ?? "100%"}
                              onChange={(width) =>
                                setVariants(
                                  variants?.map((v) => {
                                    if (v?.id === variant?.id) {
                                      return {
                                        ...v,
                                        image: {
                                          ...v?.image,
                                          width,
                                        },
                                      };
                                    }
                                    return v;
                                  })
                                )
                              }
                              placeholder="100%"
                              autoComplete="off"
                            />
                            <TextField
                              label="Image Width (Mobile)"
                              value={variant?.image?.widthMobile ?? "100%"}
                              onChange={(widthMobile) =>
                                setVariants(
                                  variants?.map((v) => {
                                    if (v?.id === variant?.id) {
                                      return {
                                        ...v,
                                        image: {
                                          ...v?.image,
                                          widthMobile,
                                        },
                                      };
                                    }
                                    return v;
                                  })
                                )
                              }
                              placeholder="100%"
                              autoComplete="off"
                            />
                            <Select
                              label="Image Position"
                              options={[
                                { label: "Top", value: "top" },
                                { label: "Bottom", value: "bottom" },
                                { label: "Left", value: "left" },
                                { label: "Right", value: "right" },
                              ]}
                              value={variant?.image?.position ?? "bottom"}
                              onChange={(position) =>
                                setVariants(
                                  variants?.map((v) => {
                                    if (v?.id === variant?.id) {
                                      return {
                                        ...v,
                                        image: {
                                          ...v?.image,
                                          position,
                                        },
                                      };
                                    }
                                    return v;
                                  })
                                )
                              }
                            />
                          </HorizontalSettingsContainer>
                        )}
                      </ImageSettingsContainer>
                      <ProductSettingsContainer>
                        <HorizontalSettingsContainer>
                          <Checkbox
                            label={
                              <>
                                ✨
                                <span className={styles.uberText}>
                                  Include product recommendations
                                </span>
                                ✨
                              </>
                            }
                            checked={variant?.productRecommendations?.show}
                            onChange={(v) =>
                              setVariants(
                                variants?.map((v) => {
                                  if (v?.id === variant?.id) {
                                    return {
                                      ...v,
                                      productRecommendations: {
                                        ...v?.productRecommendations,
                                        show: v?.productRecommendations?.show
                                          ? false
                                          : true,
                                      },
                                    };
                                  }
                                  return v;
                                })
                              )
                            }
                          />
                        </HorizontalSettingsContainer>
                        {!!variant?.productRecommendations?.show &&
                          variant?.productRecommendations?.selectedProducts
                            ?.length > 0 && (
                            <HorizontalSettingsContainer>
                              {variant?.productRecommendations?.selectedProducts?.map(
                                (product) => {
                                  return (
                                    <ProductContainer key={product?.id}>
                                      <Tooltip
                                        content={product?.title}
                                        dismissOnMouseOut
                                      >
                                        <Title>{product?.title}</Title>
                                      </Tooltip>
                                      <Button
                                        plain
                                        destructive
                                        size="slim"
                                        icon={CancelSmallMinor as IconSource}
                                        onClick={() => {
                                          setVariants(
                                            variants?.map((v) => {
                                              if (v?.id === variant?.id) {
                                                return {
                                                  ...v,
                                                  productRecommendations: {
                                                    ...v?.productRecommendations,
                                                    selectedProducts:
                                                      v?.productRecommendations?.selectedProducts?.filter(
                                                        (p) =>
                                                          p?.id !== product?.id
                                                      ),
                                                  },
                                                };
                                              }
                                              return v;
                                            })
                                          );
                                        }}
                                      />
                                    </ProductContainer>
                                  );
                                }
                              )}
                            </HorizontalSettingsContainer>
                          )}
                        {!!variant?.productRecommendations?.show &&
                          variant?.productRecommendations?.selectedVariants
                            ?.length > 0 && (
                            <HorizontalSettingsContainer>
                              {variant?.productRecommendations?.selectedVariants?.map(
                                (product) => {
                                  return product?.variants?.map((v) => {
                                    return (
                                      <VariantContainer key={v?.id}>
                                        <Tooltip
                                          content={`${product?.title} - ${v?.title}`}
                                          dismissOnMouseOut
                                        >
                                          <Title>{`${product?.title} - ${v?.title}`}</Title>
                                        </Tooltip>
                                        <Button
                                          plain
                                          destructive
                                          size="slim"
                                          icon={CancelSmallMinor as IconSource}
                                          onClick={() => {
                                            const newVariants =
                                              product?.variants?.filter(
                                                (p) => p?.id !== v?.id
                                              );
                                            if (!newVariants?.length) {
                                              // remove product from selectedVariants altogether
                                              setVariants(
                                                variants?.map((x) => {
                                                  if (x?.id === variant?.id) {
                                                    return {
                                                      ...x,
                                                      productRecommendations: {
                                                        ...x?.productRecommendations,
                                                        selectedVariants:
                                                          x?.productRecommendations?.selectedVariants?.filter(
                                                            (p) =>
                                                              p?.id !==
                                                              product?.id
                                                          ),
                                                      },
                                                    };
                                                  }
                                                  return x;
                                                })
                                              );
                                            } else {
                                              // update variants property of the product
                                              setVariants(
                                                variants?.map((x) => {
                                                  if (x?.id === variant?.id) {
                                                    return {
                                                      ...x,
                                                      productRecommendations: {
                                                        ...x?.productRecommendations,
                                                        selectedVariants:
                                                          x?.productRecommendations?.selectedVariants?.map(
                                                            (p) => {
                                                              if (
                                                                p?.id ===
                                                                product?.id
                                                              ) {
                                                                return {
                                                                  ...p,
                                                                  variants:
                                                                    newVariants,
                                                                };
                                                              }
                                                              return p;
                                                            }
                                                          ),
                                                      },
                                                    };
                                                  }
                                                  return x;
                                                })
                                              );
                                            }
                                          }}
                                        />
                                      </VariantContainer>
                                    );
                                  });
                                }
                              )}
                            </HorizontalSettingsContainer>
                          )}
                        {!!variant?.productRecommendations?.show &&
                          variant?.productRecommendations?.selectedCollections
                            ?.length > 0 && (
                            <HorizontalSettingsContainer>
                              {variant?.productRecommendations?.selectedCollections?.map(
                                (product) => {
                                  return (
                                    <CollectionContainer key={product?.id}>
                                      <Tooltip
                                        content={product?.title}
                                        dismissOnMouseOut
                                      >
                                        <Title>{product?.title}</Title>
                                      </Tooltip>
                                      <Button
                                        plain
                                        destructive
                                        size="slim"
                                        icon={CancelSmallMinor as IconSource}
                                        onClick={() => {
                                          setVariants(
                                            variants?.map((v) => {
                                              if (v?.id === variant?.id) {
                                                return {
                                                  ...v,
                                                  productRecommendations: {
                                                    ...v?.productRecommendations,
                                                    selectedCollections:
                                                      v?.productRecommendations?.selectedCollections?.filter(
                                                        (p) =>
                                                          p?.id !== product?.id
                                                      ),
                                                  },
                                                };
                                              }
                                              return v;
                                            })
                                          );
                                        }}
                                      />
                                    </CollectionContainer>
                                  );
                                }
                              )}
                            </HorizontalSettingsContainer>
                          )}
                        {!!variant?.productRecommendations?.show && (
                          <>
                            <HorizontalSettingsContainer>
                              <Button
                                icon={ProductsMinor as IconSource}
                                primary
                                onClick={() => {
                                  setTargetVariantForProductRecommendations(
                                    variant?.id
                                  );
                                  window?.top?.frames?.[
                                    "app-iframe"
                                  ]?.postMessage(
                                    {
                                      type: "product",
                                      payload: {
                                        selectedOptions:
                                          variant?.productRecommendations?.selectedProducts?.map(
                                            ({ id }) => {
                                              return id;
                                            }
                                          ),
                                      },
                                      target: `productBlock`,
                                    },
                                    "*"
                                  );
                                }}
                              >
                                Add Products
                              </Button>
                              <Button
                                icon={VariantMajor as IconSource}
                                primary
                                onClick={() => {
                                  const selection =
                                    variant?.productRecommendations
                                      ?.selectedVariants ?? [];
                                  const variantIds = [] as string[];
                                  for (const product of selection) {
                                    const productId = product.id;
                                    const variants = product.variants;
                                    for (const variant of variants) {
                                      variantIds.push(
                                        `${productId}###variant###${variant.id}`
                                      );
                                    }
                                  }
                                  setTargetVariantForProductRecommendations(
                                    variant?.id
                                  );
                                  window?.top?.frames?.[
                                    "app-iframe"
                                  ]?.postMessage(
                                    {
                                      type: "variant",
                                      payload: {
                                        selectedVariantOptions: variantIds,
                                      },
                                      target: `productBlock`,
                                    },
                                    "*"
                                  );
                                }}
                              >
                                Add Variants
                              </Button>
                              <Button
                                icon={CollectionsMajor as IconSource}
                                primary
                                onClick={() => {
                                  setTargetVariantForProductRecommendations(
                                    variant?.id
                                  );
                                  window?.top?.frames?.[
                                    "app-iframe"
                                  ]?.postMessage(
                                    {
                                      type: "collection",
                                      payload: {
                                        selectedCollectionOptions:
                                          variant?.productRecommendations?.selectedCollections?.map(
                                            ({ id }) => {
                                              return id;
                                            }
                                          ),
                                      },
                                      target: `productBlock`,
                                    },
                                    "*"
                                  );
                                }}
                              >
                                Add Collections
                              </Button>
                            </HorizontalSettingsContainer>
                            <HorizontalSettingsContainer>
                              <TextField
                                label="Block Width (Desktop)"
                                value={
                                  variant?.productRecommendations?.width ??
                                  "100%"
                                }
                                onChange={(width) =>
                                  setVariants(
                                    variants?.map((v) => {
                                      if (v?.id === variant?.id) {
                                        return {
                                          ...v,
                                          productRecommendations: {
                                            ...v?.productRecommendations,
                                            width,
                                          },
                                        };
                                      }
                                      return v;
                                    })
                                  )
                                }
                                placeholder="100%"
                                autoComplete="off"
                              />
                              <TextField
                                label="Block Width (Mobile)"
                                value={
                                  variant?.productRecommendations
                                    ?.widthMobile ?? "100%"
                                }
                                onChange={(widthMobile) =>
                                  setVariants(
                                    variants?.map((v) => {
                                      if (v?.id === variant?.id) {
                                        return {
                                          ...v,
                                          productRecommendations: {
                                            ...v?.productRecommendations,
                                            widthMobile,
                                          },
                                        };
                                      }
                                      return v;
                                    })
                                  )
                                }
                                placeholder="100%"
                                autoComplete="off"
                              />
                              <Select
                                label="Block Position"
                                options={[
                                  { label: "Top", value: "top" },
                                  { label: "Bottom", value: "bottom" },
                                  { label: "Left", value: "left" },
                                  { label: "Right", value: "right" },
                                ]}
                                value={
                                  variant?.productRecommendations?.position ??
                                  "bottom"
                                }
                                onChange={(position) =>
                                  setVariants(
                                    variants?.map((v) => {
                                      if (v?.id === variant?.id) {
                                        return {
                                          ...v,
                                          productRecommendations: {
                                            ...v?.productRecommendations,
                                            position,
                                          },
                                        };
                                      }
                                      return v;
                                    })
                                  )
                                }
                              />
                            </HorizontalSettingsContainer>
                            <HorizontalSettingsContainer>
                              <Checkbox
                                label="Show Match Score"
                                checked={
                                  variant?.productRecommendations
                                    ?.showMatchScore ?? true
                                }
                                onChange={(value) =>
                                  setVariants(
                                    variants?.map((v) => {
                                      if (v?.id === variant?.id) {
                                        return {
                                          ...v,
                                          productRecommendations: {
                                            ...v?.productRecommendations,
                                            showMatchScore: value,
                                          },
                                        };
                                      }
                                      return v;
                                    })
                                  )
                                }
                              />
                            </HorizontalSettingsContainer>
                            <HorizontalSettingsContainer>
                              <Select
                                label="Sorting"
                                options={[
                                  {
                                    label: "Default (Best for Collections)",
                                    value: "default",
                                  },
                                  {
                                    label: "Price (Low to High)",
                                    value: "price-ascending",
                                  },
                                  {
                                    label: "Price (High to Low)",
                                    value: "price-descending",
                                  },
                                  {
                                    label: "Title (A-Z)",
                                    value: "title-ascending",
                                  },
                                  {
                                    label: "Title (Z-A)",
                                    value: "title-descending",
                                  },
                                ]}
                                helpText="If you're connecting collections it's best to choose Default to keep the sorting method provided by your collections"
                                value={
                                  variant?.productRecommendations?.sorting ??
                                  "default"
                                }
                                onChange={(sorting) =>
                                  setVariants(
                                    variants?.map((v) => {
                                      if (v?.id === variant?.id) {
                                        return {
                                          ...v,
                                          productRecommendations: {
                                            ...v?.productRecommendations,
                                            sorting,
                                          },
                                        };
                                      }
                                      return v;
                                    })
                                  )
                                }
                              />
                            </HorizontalSettingsContainer>
                            <HorizontalSettingsContainer>
                              <RangeSlider
                                label="Total Products To Show"
                                value={
                                  variant?.productRecommendations
                                    ?.totalProducts ?? 3
                                }
                                onChange={(totalProducts) =>
                                  setVariants(
                                    variants?.map((v) => {
                                      if (v?.id === variant?.id) {
                                        return {
                                          ...v,
                                          productRecommendations: {
                                            ...v?.productRecommendations,
                                            totalProducts,
                                          },
                                        };
                                      }
                                      return v;
                                    })
                                  )
                                }
                                min={1}
                                max={12}
                                output
                                prefix={
                                  variant?.productRecommendations
                                    ?.totalProducts ?? 3
                                }
                              />
                              <RangeSlider
                                label="Products Per Row (Desktop)"
                                value={
                                  variant?.productRecommendations
                                    ?.productsPerRowDesktop ?? 3
                                }
                                onChange={(productsPerRowDesktop) =>
                                  setVariants(
                                    variants?.map((v) => {
                                      if (v?.id === variant?.id) {
                                        return {
                                          ...v,
                                          productRecommendations: {
                                            ...v?.productRecommendations,
                                            productsPerRowDesktop,
                                          },
                                        };
                                      }
                                      return v;
                                    })
                                  )
                                }
                                min={1}
                                max={10}
                                output
                                prefix={
                                  variant?.productRecommendations
                                    ?.productsPerRowDesktop ?? 3
                                }
                              />
                              <RangeSlider
                                label="Products Per Row (Mobile)"
                                value={
                                  variant?.productRecommendations
                                    ?.productsPerRowMobile ?? 1
                                }
                                onChange={(productsPerRowMobile) =>
                                  setVariants(
                                    variants?.map((v) => {
                                      if (v?.id === variant?.id) {
                                        return {
                                          ...v,
                                          productRecommendations: {
                                            ...v?.productRecommendations,
                                            productsPerRowMobile,
                                          },
                                        };
                                      }
                                      return v;
                                    })
                                  )
                                }
                                min={1}
                                max={10}
                                output
                                prefix={
                                  variant?.productRecommendations
                                    ?.productsPerRowMobile ?? 1
                                }
                              />
                            </HorizontalSettingsContainer>
                          </>
                        )}
                      </ProductSettingsContainer>
                      <div id={`Editor-Container-Variant-${variant?.id}`}>
                        <RichTextEditor
                          variantId={variant?.id}
                          value={variant?.content}
                          setValue={(v) => updateVariantContent(variant?.id, v)}
                        />
                      </div>
                      <Stack alignment="center">
                        <PolarisButton
                          icon={DuplicateMinor as IconSource}
                          size="slim"
                          onClick={() => cloneVariant(variant?.id)}
                        >
                          Clone Variant
                        </PolarisButton>
                        <PolarisButton
                          icon={
                            variant?.answers?.length
                              ? (EditMinor as IconSource)
                              : (PlusMinor as IconSource)
                          }
                          size="slim"
                          onClick={() => showAnswerMatching(variant?.id)}
                        >
                          {variant?.answers?.length ? "Edit" : "Add"} matching
                          answers
                        </PolarisButton>
                        {variant?.answers?.map((answer, aidx) => {
                          return (
                            <BadgeContainer key={answer?.id}>
                              <Tooltip
                                content={`Weight: ${
                                  variant?.answersWeight?.[answer?.id] ?? 100
                                }`}
                                dismissOnMouseOut
                              >
                                <Badge
                                  status={
                                    variant?.answersWeight?.[answer?.id] <= 0
                                      ? "critical"
                                      : "success"
                                  }
                                >
                                  {
                                    (
                                      <BadgeContent>
                                        <span>{answer?.label}</span>
                                        <Button
                                          plain
                                          destructive
                                          size="slim"
                                          icon={CancelSmallMinor as IconSource}
                                          onClick={() => {
                                            //remove answer from variant
                                            setVariants(
                                              variants?.map((v) => {
                                                if (v?.id === variant?.id) {
                                                  return {
                                                    ...v,
                                                    answers: v?.answers?.filter(
                                                      (a) =>
                                                        a?.id !== answer?.id
                                                    ),
                                                  };
                                                }
                                                return v;
                                              })
                                            );
                                          }}
                                        />
                                      </BadgeContent>
                                    ) as unknown as string
                                  }
                                </Badge>
                              </Tooltip>
                            </BadgeContainer>
                          );
                        })}
                      </Stack>
                    </Stack>
                  </Box>
                );
              })}
            <PolarisButton
              fullWidth
              icon={AddMajor as IconSource}
              onClick={() => addMoreVariants()}
            >
              Add More Variants
            </PolarisButton>
            <br />
            <Stack alignment="center" distribution="center">
              <Pagination
                label={`Variants ${PAGE_SIZE * (page - 1) + 1} - ${
                  PAGE_SIZE * page < variants.length
                    ? PAGE_SIZE * page
                    : variants.length
                } of ${variants?.length}`}
                hasPrevious={page > 1}
                onPrevious={() => setPage(page - 1)}
                hasNext={variants?.length > page * PAGE_SIZE}
                onNext={() => setPage(page + 1)}
              />
            </Stack>
          </Stack>
        </Modal.Section>
      </Modal>
      <MatchAnswersModal
        nodes={nodes}
        open={answerMatching}
        onClose={hideAnswerMatching}
        addAnswers={addAnswers}
        variantNumber={
          (variants?.findIndex((v) => v?.id === targetVariant) ?? 0) + 1
        }
        weights={variants?.find((v) => v?.id === targetVariant)?.answersWeight}
        answers={variants?.find((v) => v?.id === targetVariant)?.answers}
      />

      <MediaModal
        isOpen={showFilePicker}
        setOpen={setShowFilePicker}
        onDrop={async (v) => {
          await handleDropZoneDrop(null, v, null);
        }}
        onSelectImage={(newImage) => {
          setFile(newImage?.blurhash ?? uuidv4(), newImage?.url);
        }}
        onSelectVideo={(newVideo) => {
          setFile(newVideo?.blurhash ?? uuidv4(), newVideo?.url);
        }}
        onSelectGif={(newGif) => {
          setFile(newGif?.blurhash ?? uuidv4(), newGif?.url);
        }}
        isBusy={uploading}
        types={["image", "gif"]}
      />

      <Modal
        open={showThrottlingModal}
        onClose={() => setShowThrottlingModal(false)}
        title="Linking Collection"
      >
        <Modal.Section>
          <Stack alignment="center" vertical={true}>
            <Spinner size="large" />
            <h1>Fetching products from Shopify...</h1>
            {throttlingModalMessage && (
              <p>
                <b>IMPORTANT:</b> {throttlingModalMessage}
              </p>
            )}
          </Stack>
        </Modal.Section>
      </Modal>

      {/* <Modal
        open={showFilePicker}
        onClose={closeFilePicker}
        title="Upload Image"
      >
        <Modal.Section>
          <DropZone allowMultiple={false} onDrop={handleDropZoneDrop}>
            {!uploading && <DropZone.FileUpload />}
            {uploading && (
              <UploadingContainer>
                <ProgressText>
                  Uploading Image: {`${progress}% complete`}
                </ProgressText>
                <ProgressBarContainer>
                  <ProgressBar progress={progress} size="small" />
                </ProgressBarContainer>
              </UploadingContainer>
            )}
          </DropZone>
        </Modal.Section>
      </Modal> */}
    </>
  );
};

export default ContentBlockModal;

// const UploadingContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   gap: 16px;
//   margin-top: 16px;
// `;

// const ProgressBarContainer = styled.div`
//   width: 250px;
// `;

// const ProgressText = styled.span`
//   font-size: 12px;
//   font-weight: 500;
//   color: #637381;
// `;

const BadgeContainer = styled.div`
  cursor: pointer;
`;

const BadgeContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & button {
    padding: 0;
  }
`;

const SettingsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
  margin: 16px 0;
`;

const ImageSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
`;

const HorizontalSettingsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;

  & > label {
    min-width: 150px;
  }
`;

const ImageNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
`;

const ImageName = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #637381;
`;

const ProductSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
`;

const Title = styled.p`
  max-width: 7.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ProductContainer = styled.div`
  background-color: #aee9d1;
  padding: 0.25rem 0.5rem;
  width: 9.8rem;
  font-size: 14;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
`;

const VariantContainer = styled.div`
  background-color: #fff065;
  padding: 0.25rem 0.5rem;
  width: 9.8rem;
  font-size: 14;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
`;

const CollectionContainer = styled.div`
  background-color: #aec7e9;
  padding: 0.25rem 0.5rem;
  width: 9.8rem;
  font-size: 14;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
`;
