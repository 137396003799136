import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';

import { useFlow } from '~/hooks';

interface FormSyncProps {
  control: any;
  flowNodeId: string;
}

function compareObjects(a, b) {
  if (a === b) {
      return true;
  }
  if (a === null && b === null) {
      return true;
  }
  if (a === null || b === null) {
      return false;
  }
  if (typeof a !== 'object' || typeof b !== 'object') {
      return false;
  }

  for (let key in a) {
      if (a.hasOwnProperty(key) && key !== "fieldKey") {
          if (!b.hasOwnProperty(key)) {
              return false;
          } else if (typeof a[key] === 'object' && a[key] !== null && typeof b[key] === 'object' && b[key] !== null) {
              if (!compareObjects(a[key], b[key])) {
                  return false;
              }
          } else if (a[key] !== b[key]) {
              return false;
          }
      }
  }
  return true;
}


export const useFormSync = ({ control, flowNodeId }: FormSyncProps) => {
  const { updateFlowNode, lastUpdate } = useFlow();
  const values = useWatch({
    control,
  });

  useEffect(() => {
    if (flowNodeId && values) {
      if (!compareObjects(values, lastUpdate)) {
        updateFlowNode(flowNodeId, values);
      }
    }
  }, [values]);
};
